aside.faq {
    position: fixed;
    height: calc(100vh - var(--topbar-height));
    width: 100%;
    max-width: 50rem;

    top: var(--topbar-height);
    right: 0;

    padding: var(--main-padding);
    box-sizing: border-box;
    user-select: none;

    background-color: $white;
    box-shadow: 0 0 1rem 0 $lightgrey;

    overflow-y: auto;

    z-index: 5;

    transform: translateX(100%);

    transition: transform .5s ease-in-out;
    will-change: transform;

    a {
        color: var(--main-color);
    }

    &.open {
        transform: translateX(0);
    }

    .faq-content {
        margin-top: 2rem;
    }

    .faq-title {
        margin-bottom: 1rem;
        margin-top: 2rem;
        display: block;
        color: var(--secondary-color);
    }

    .faq-questions {
        list-style-type: none;
        display: grid;
        gap: 1rem;
    }

    .faq-question {
        strong {
            color: $darkgrey;
            transition: color .25s;
        }

        .title {
            background-color: $lightgrey;
            padding: .5rem 2rem;
            cursor: pointer;
            transition: background-color .25s;
        }

        .content {
            display: none;
        }

        .inner-content {
            padding: 2rem;
            width: 100%;
            box-sizing: border-box;

            > {
                *+* {
                    margin-top: 1em;
                }
            }

            ul,
            ol {
                margin-left: 1em;
            }
        }

        &.active {
            .content {
                user-select: text;
            }
        }

        &:hover,
        &.active {
            .title {
                background-color: var(--secondary-color);

                strong {
                    color: $white;
                }
            }
        }
    }
}