.main-fields.user-permissions {
    align-self: start;
    
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0;

    max-width: 100%;
    width: auto;

    padding: calc(var(--main-padding) / 1.5);
    box-sizing: border-box;
    margin-top: 2rem;

    background-color: $darkgrey;
    color: $white;

    fieldset {
        margin-bottom: 0;
        width: auto;
    }

    .field {
        margin-bottom: 0;
    }

    label,
    legend {
        color: inherit;
    }

    legend {
        font-weight: 700;
        color: var(--main-color);
    }

    #admin_page_permissions {
        min-height: 500px;
    }
}