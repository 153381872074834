.cp-version-table {
    margin-top: 1rem;

    display: grid;
    grid-template-columns: auto 1fr;

    //gap: calc(var(--main-padding) / 2);
    gap: var(--main-padding);

    thead {
        tr {
            font-size: 1.35rem;

            td {
                padding-bottom: .5rem;
            }
        }
    }

    th {
        text-align: left;
        font-weight: 700;
        padding-right: 1em;
    }

    + h2 {
        margin-top: calc(var(--main-padding )/ 2);
        margin-bottom: calc(var(--main-padding )/ 2);
    }
}

.cp-version-form {
    .radio-container {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        .field {
            margin-bottom: 0;
        }
    }

    textarea {
        padding: 1.25rem 1.5rem;
    }

    .hidden {
        display: none;
    }
}