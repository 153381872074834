.dashboard {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    align-content: start;
    display: grid;
    gap: 2rem;

    > h2 {
        grid-column: 1/-1;
    }

    section {
        transition: transform .25s;
        transform-origin: 50% 100%;
        background-color: $white;
        isolation: isolate;
        position: relative;
        display: flex;
        &::after {
            transition: opacity .25s, transform .25s;
            box-shadow: 0 0.5em 0.75em rgba($black, 0.125);
            position: absolute;
            content: '';
            z-index: -1;
            opacity: 0;
            inset: 0;
        }
        &:hover {
            transform: scale(1.025) translateY(-0.25em);
            &::after {
                opacity: 1;
            }
        }
        > a, button {
            text-align: left;
            color: inherit;
            padding: 2rem;
        }
        p {
            a {
                white-space: nowrap;
                color: var(--main-color);
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        button {
            user-select: auto;
        }
    }
}

@media (max-width: 1400px) {
    .dashboard {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
@media (max-width: 800px) {
    .dashboard {
        grid-template-columns: 1fr;
    }
}