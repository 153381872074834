@import url("https://use.typekit.net/rit3tvj.css");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --nav-width: 20rem;
  --topbar-height: 5rem;
  --sidebar-width: 30%;
  --main-padding: 4rem;
  --main-color: #ff5600;
  --secondary-color: #405B85;
  --main-color-alt: #ff7833;
  --secondary-color-alt: #5173a7;
  --main-color-dark: #b33c00;
  --secondary-color-dark: #273851;
}

@media (max-width: 1800px) {
  :root {
    --nav-width: 18rem;
    --main-padding: 3rem;
  }
}
@media (max-width: 1400px) {
  :root {
    --main-padding: 2.5rem;
  }
}
@media (max-width: 1000px) {
  :root {
    --nav-width: 20rem;
  }
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, form .label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  outline: none;
  vertical-align: baseline;
  text-decoration: none;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0px 9999px #ECEBF0;
  -webkit-text-fill-color: inherit;
}

select::-ms-expand {
  display: none;
}

textarea {
  overflow: auto;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input, input:focus {
  outline: none;
}

input, textarea, select {
  box-sizing: border-box;
  display: inline-block;
  line-height: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  border-radius: 0;
  appearance: none;
  background: none;
  color: inherit;
  outline: none;
  border: none;
  width: auto;
  padding: 0;
  margin: 0;
}

button, .button {
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  line-height: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: inherit;
  user-select: none;
  border-radius: 0;
  appearance: none;
  background: none;
  cursor: pointer;
  color: inherit;
  outline: none;
  border: none;
  width: auto;
  padding: 0;
  margin: 0;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes line1-open {
  0% {
    transform: translate(-50%, inherit);
  }
  50% {
    transform: translate(-50%, 25%);
  }
  100% {
    transform: translate(-50%, 0) rotate(45deg);
  }
}
@keyframes line2-open {
  0% {
    transform: translate(-50%, inherit);
  }
  50% {
    transform: translate(-50%, -25%);
  }
  100% {
    transform: translate(-50%, 0) rotate(-45deg);
  }
}
@keyframes line1-closed {
  0% {
    transform: translate(-50%, 25%) rotate(45deg);
  }
  50% {
    transform: translate(-50%, 25%) rotate(0);
  }
  100% {
    transform: translate(-50%, inherit) rotate(0);
  }
}
@keyframes line2-closed {
  0% {
    transform: translate(-50%, -25%) rotate(-45deg);
  }
  50% {
    transform: translate(-50%, -25%) rotate(0);
  }
  100% {
    transform: translate(-50%, inherit) rotate(0);
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 10px;
  transform: scale(0.75);
  margin-top: 1rem;
  transform-origin: left;
}

.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #ECEBF0;
}

main {
  min-height: calc(100vh - var(--topbar-height));
  width: calc(100% - var(--nav-width));
  margin-top: var(--topbar-height);
  margin-left: var(--nav-width);
  padding: var(--main-padding);
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.main-content {
  flex-direction: column;
  display: flex;
}
.main-content.full-width {
  grid-column: span 2;
}
.main-content > .main-fields:where(:not(.user-permissions)) {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}
.main-content > .main-fields:where(:not(.user-permissions)) .field, .main-content > .main-fields:where(:not(.user-permissions)) .cke, .main-content > .main-fields:where(:not(.user-permissions)) .cke_inner {
  flex-direction: column;
  display: flex;
}
.main-content > .main-fields:where(:not(.user-permissions)) .field, .main-content > .main-fields:where(:not(.user-permissions)) .cke, .main-content > .main-fields:where(:not(.user-permissions)) .cke_inner, .main-content > .main-fields:where(:not(.user-permissions)) .cke_contents {
  flex-grow: 1;
}

.content-grid {
  display: grid;
  grid-template-columns: calc(100% - var(--sidebar-width) - var(--main-padding)) var(--sidebar-width);
  gap: calc(var(--main-padding) / 2) var(--main-padding);
}
.content-grid .grid-title {
  grid-column: span 2;
  user-select: none;
}
.content-grid .grid-title h2 {
  color: var(--main-color);
}

.cms-form .content-grid {
  display: none;
}
.cms-form .content-grid.visible {
  display: grid;
}

[hidden] {
  display: none;
}

@media (max-width: 1000px) {
  body.nav-open .topbar, body.nav-open main {
    transform: translateX(var(--nav-width));
  }
  main {
    transition: transform 0.25s;
    margin-left: 0;
    width: 100%;
  }
}
html {
  font: 400 14px/1.75em open-sans, sans-serif;
}

body {
  font: inherit;
  color: #2E2E2D;
}

h1 {
  font-family: serenity, sans-serif;
  line-height: 1em;
  font-size: 3rem;
  font-weight: 700;
  color: #2E2E2D;
}
h1.orange {
  color: var(--main-color);
}

h2 {
  font-family: serenity, sans-serif;
  font-weight: 600;
  line-height: 1em;
  font-size: 2rem;
}

h3,
b,
strong {
  font-family: serenity, sans-serif;
  color: var(--main-color);
  font-weight: 700;
  font-size: 1.2rem;
}

em {
  font-style: italic;
}

a:not[class] {
  color: #000;
  transition: color 0.25s;
}
a:not[class]:hover {
  color: var(--main-color);
}

mark {
  background-color: #ECEBF0;
  font-family: monospace;
  padding: 0 0.25em;
}

small {
  font-size: 0.75rem;
}

s {
  text-decoration: line-through;
}

.page-title {
  position: absolute;
  left: var(--main-padding);
  bottom: 0;
  transform: translateY(50%);
}

@media (max-width: 1800px) {
  html {
    font-size: 13px;
  }
}
@media (max-width: 1400px) {
  html {
    font-size: 12px;
  }
}
@media (max-width: 1000px) {
  .page-title {
    margin-right: var(--main-padding);
    align-self: center;
    position: static;
    transform: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    max-width: 100%;
    font-size: 2rem;
  }
}
button svg, button img {
  pointer-events: none;
}

.button {
  background-color: var(--main-color);
  padding: 0.75em 1.5em;
  color: #FFF;
  font-family: serenity, sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.25s;
}
.button[type=submit].loading {
  pointer-events: none;
}
.button[type=submit].loading:after {
  background-image: url("../content/img/icon-loading.svg");
  animation: loading 1s linear infinite;
}
.button[type=submit][disabled] {
  background-color: #636362;
  pointer-events: none;
}
.button:after {
  content: "";
  display: flex;
  aspect-ratio: 1;
  width: 0.75em;
  height: auto;
  background-image: url("../content/img/icon-next.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  margin-left: 0.75em;
}
.button:hover {
  background-color: #cc4500;
}

.back-button {
  align-items: center;
  display: flex;
  gap: 0.75em;
  color: var(--main-color);
}
.back-button:hover {
  text-decoration: underline;
}
.back-button svg {
  display: inline-block;
  fill: currentColor;
  width: 0.5em;
  height: auto;
}

.small-button {
  transition: background-color 0.25s;
  color: #FFF;
  font-family: serenity, sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 0.375em 0.75em;
  background-color: var(--secondary-color);
}
.small-button.orange {
  background-color: var(--main-color);
}
.small-button.orange:hover {
  background-color: #cc4500;
}
.small-button:hover {
  background-color: #2f4363;
}

.inline-button {
  font-style: italic;
  text-decoration: none;
  color: var(--secondary-color);
  font-family: serenity, sans-serif;
  font-weight: 600;
}
.inline-button.white {
  color: #FFF;
}
.inline-button.clear-field {
  transition: opacity 0.25s;
  pointer-events: none;
  color: var(--main-color);
  opacity: 0;
}
.inline-button:hover {
  text-decoration: underline;
}

.alt-button {
  align-self: stretch;
  align-items: center;
  display: flex;
  gap: 0.25em;
  margin-left: 0.25rem;
  color: rgba(46, 46, 45, 0.5);
  white-space: nowrap;
  line-height: 1em;
  font-size: 0.9rem;
  transition: color 0.25s;
}
.alt-button:hover {
  color: var(--main-color);
}
.alt-button svg {
  line-height: 0;
  display: block;
  height: auto;
  width: 1em;
}

@media (max-width: 1200px) {
  .inline-button {
    font-size: 0.9em;
  }
  .button {
    font-size: 1.2rem;
  }
  .small-button {
    font-size: 1rem;
  }
}
.lang-switcher {
  list-style: none;
  display: flex;
}
.lang-switcher li {
  display: flex;
}
.lang-switcher .small-button {
  background-color: var(--main-color);
}
.lang-switcher .small-button.active {
  background-color: var(--secondary-color);
}

.tox .tox-promotion-link {
  display: none !important;
}

.tox-statusbar__branding {
  display: none !important;
}

.tox-tinymce {
  width: 100%;
}

.tox-fullscreen {
  top: calc(var(--topbar-height) + 2rem) !important;
  left: var(--nav-width) !important;
  width: calc(100vw - var(--nav-width)) !important;
  height: calc(100vh - var(--topbar-height) - 2rem) !important;
}

.mce-content-body {
  padding: 20px !important;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.6em;
}
.mce-content-body * {
  margin: 0;
}
.mce-content-body strong {
  font-weight: 500;
}
.mce-content-body table {
  border-collapse: collapse;
}
.mce-content-body th {
  font-weight: 700;
}
.mce-content-body td {
  border: 1px solid black;
}

aside.faq {
  position: fixed;
  height: calc(100vh - var(--topbar-height));
  width: 100%;
  max-width: 50rem;
  top: var(--topbar-height);
  right: 0;
  padding: var(--main-padding);
  box-sizing: border-box;
  user-select: none;
  background-color: #FFF;
  box-shadow: 0 0 1rem 0 #ECEBF0;
  overflow-y: auto;
  z-index: 5;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}
aside.faq a {
  color: var(--main-color);
}
aside.faq.open {
  transform: translateX(0);
}
aside.faq .faq-content {
  margin-top: 2rem;
}
aside.faq .faq-title {
  margin-bottom: 1rem;
  margin-top: 2rem;
  display: block;
  color: var(--secondary-color);
}
aside.faq .faq-questions {
  list-style-type: none;
  display: grid;
  gap: 1rem;
}
aside.faq .faq-question strong {
  color: #2E2E2D;
  transition: color 0.25s;
}
aside.faq .faq-question .title {
  background-color: #ECEBF0;
  padding: 0.5rem 2rem;
  cursor: pointer;
  transition: background-color 0.25s;
}
aside.faq .faq-question .content {
  display: none;
}
aside.faq .faq-question .inner-content {
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
}
aside.faq .faq-question .inner-content > * + * {
  margin-top: 1em;
}
aside.faq .faq-question .inner-content ul,
aside.faq .faq-question .inner-content ol {
  margin-left: 1em;
}
aside.faq .faq-question.active .content {
  user-select: text;
}
aside.faq .faq-question:hover .title, aside.faq .faq-question.active .title {
  background-color: var(--secondary-color);
}
aside.faq .faq-question:hover .title strong, aside.faq .faq-question.active .title strong {
  color: #FFF;
}

.message {
  display: none;
}
.message.success {
  color: var(--secondary-color);
}
.message.error {
  color: var(--main-color);
}

form h1 {
  margin-bottom: 1rem;
}
form .slug-container {
  line-height: 1em;
  white-space: nowrap;
  display: flex;
  gap: 1rem 0;
  width: 100%;
  max-width: 45rem;
}
form .slug-container p {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: #FFF;
  padding-left: 1rem;
}
form .slug-container input:not([type=checkbox]) {
  margin-bottom: 0;
  padding-left: 0;
}
form .slug-container a {
  padding-inline: 1rem;
  align-items: center;
  display: flex;
  color: #FFF;
  gap: 0.5em;
}
form .slug-container a[href="#"] {
  pointer-events: none;
  opacity: 0.5;
}
form .slug-container a:hover {
  text-decoration: underline;
}
form .slug-container a svg {
  height: auto;
  width: 1em;
}
form label, form .label,
form legend {
  justify-content: space-between;
  align-items: center;
  user-select: none;
  display: flex;
  color: #2E2E2D;
  font-size: 1.2rem;
}
form small {
  color: rgba(46, 46, 45, 0.5);
  margin-left: 0.25rem;
}
form fieldset.layer-types {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0 1rem;
}
form fieldset:where(:not(:last-child):not(:last-of-type)) {
  margin-bottom: 1rem;
}
form fieldset .personal-data-grid {
  display: grid;
  grid-template-columns: 1fr 175px 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}
form fieldset .personal-data-grid .field {
  margin-bottom: 0;
}
form fieldset label, form fieldset .label,
form fieldset legend {
  padding-bottom: 0.5rem;
  width: 100%;
}
form .base-fields,
form .main-fields,
form .custom-fields {
  width: 100%;
}
form .main-fields:empty,
form .custom-fields:empty {
  display: none;
}
form .base-fields {
  background-color: var(--secondary-color);
  padding: calc(var(--main-padding) / 1.5);
  box-sizing: border-box;
}
form .base-fields label, form .base-fields .label,
form .base-fields legend {
  color: #FFF;
}
form .base-fields small {
  color: rgba(255, 255, 255, 0.5);
}
form .main-fields-empty {
  pointer-events: none;
  user-select: none;
  font-size: 1.25em;
  line-height: 1.75em;
  max-width: 45rem;
  text-align: center;
  padding: 1em;
  margin: auto;
}
form .main-fields input[type=checkbox] + label:before, form .main-fields input[type=checkbox] + .label:before {
  background-color: #FFF;
}
form .custom-fields {
  padding: calc(var(--main-padding) / 1.5);
  margin-top: var(--main-padding);
  box-sizing: border-box;
}
form .custom-fields .inline-button {
  margin-left: auto;
}
form .custom-fields .small-button:not(:first-of-type) {
  margin-left: 1rem;
}
form .cke {
  user-select: none;
  width: 100%;
}
form .grey-fields, form .custom-fields {
  background-color: #FFF;
}
form .grey-fields input, form .custom-fields input,
form .grey-fields textarea,
form .custom-fields textarea,
form .grey-fields select,
form .custom-fields select {
  background-color: #ECEBF0;
}
form .grey-fields input[type=checkbox] + label:before, form .custom-fields input[type=checkbox] + label:before, form .grey-fields input[type=checkbox] + .label:before, form .custom-fields input[type=checkbox] + .label:before {
  background-color: #ECEBF0;
}
form .sidebar-fields {
  background-color: #FFF;
  padding: calc(var(--main-padding) / 1.5);
  box-sizing: border-box;
}
form .sidebar-fields label, form .sidebar-fields .label,
form .sidebar-fields legend {
  color: var(--secondary-color);
  font-family: serenity, sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
}
form .sidebar-fields .inline-button:last-of-type {
  margin-left: auto;
}
form .sidebar-fields .small-button {
  display: block;
}
form .sidebar-fields .small-button:first-of-type {
  margin-left: auto;
}
form .sidebar-fields .small-button:not(:first-of-type) {
  margin-left: 1rem;
}
form label, form .label,
form fieldset {
  width: 100%;
}
form input,
form select:not([multiple]) {
  height: 2.5rem;
}
form input,
form textarea,
form select {
  background-color: #FFF;
  color: black;
}
form .prefix {
  align-items: center;
  display: flex;
  width: 100%;
  gap: 0.5rem;
}
form .prefix:not(:last-child) {
  margin-bottom: 0.5rem;
}
form .prefix span {
  color: rgba(46, 46, 45, 0.5);
}
form .prefix span label, form .prefix span .label {
  padding-bottom: 0;
  color: inherit;
  font: inherit;
}
form .field {
  flex-wrap: wrap;
  position: relative;
}
form .field:where(:not([hidden])) {
  display: flex;
}
form .field:where(:not(:last-of-type)) {
  margin-bottom: 1rem;
}
form .field.alt-fields {
  font-size: 0.85em;
  width: 100%;
}
form .field.alt-fields input {
  padding-inline: 1em;
  height: 2.5em;
}
form .field .image-preview {
  display: block;
  width: 100%;
}
form .field .image-preview[src$=".svg"], form .field .image-preview[src$=".png"] {
  background-color: rgba(64, 91, 133, 0.25);
}
form .field .image-preview.small {
  object-fit: cover;
  height: 2.5rem;
  width: 2.5rem;
}
form .field .image-preview.small[src$=".svg"], form .field .image-preview.small[src$=".png"] {
  box-sizing: border-box;
  object-fit: contain;
  padding: 0.25rem;
}
form .field .image-preview.small + input {
  width: calc(100% - 2.5rem);
}
form .field .image-preview ~ .inline-button.clear-field {
  pointer-events: auto;
  opacity: 1;
}
form .field input:where(:not(:last-child)), form .field select:where(:not(:last-child)), form .field textarea:where(:not(:last-child)) {
  margin-bottom: 0.5rem;
}
form .field.hidden {
  display: none;
}
form .field.icon input[type=checkbox]:checked + label, form .field.icon input[type=checkbox]:checked + .label, form .field.icon input[type=radio]:checked + label, form .field.icon input[type=radio]:checked + .label {
  color: var(--main-color);
}
form .field.icon input[type=checkbox] + label:before, form .field.icon input[type=checkbox] + .label:before, form .field.icon input[type=radio] + label:before, form .field.icon input[type=radio] + .label:before {
  background-image: var(--layer-icon);
  background-color: #ECEBF0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 0.25rem solid #ECEBF0;
  width: 4rem;
  height: 2rem;
  margin-right: 0.75rem;
  box-sizing: content-box;
}
form .field.icon input[type=radio] + label:before, form .field.icon input[type=radio] + .label:before {
  border-radius: 0;
}
form .field input + select,
form .field input + textarea {
  margin-top: 0.5rem;
}
form input:not([type=checkbox]),
form input:not([type=radio]),
form input:not([type=submit]),
form textarea,
form select {
  width: 100%;
}
form input:not([type=checkbox]),
form input:not([type=radio]),
form input:not([type=submit]),
form textarea,
form select {
  box-sizing: border-box;
  padding: 0 1rem;
}
form input[readonly] {
  cursor: not-allowed;
}
form input[readonly] ~ .inline-button,
form input[readonly] ~ .small-button {
  margin-top: 0.5rem;
}
form input[type=file]::file-selector-button {
  display: none;
}
form input[type=checkbox], form input[type=radio] {
  pointer-events: none;
  position: absolute;
  opacity: 0;
}
form input[type=checkbox][disabled] + label, form input[type=checkbox][disabled] + .label, form input[type=radio][disabled] + label, form input[type=radio][disabled] + .label {
  opacity: 0.5;
}
form input[type=checkbox] + label, form input[type=checkbox] + .label, form input[type=radio] + label, form input[type=radio] + .label {
  transition: color 0.25s;
  display: inline-flex;
  align-items: center;
  padding-right: 1.5rem;
  font-weight: 400;
  width: auto;
}
form input[type=checkbox] + label:before, form input[type=checkbox] + .label:before, form input[type=radio] + label:before, form input[type=radio] + .label:before {
  transition: background-color 0.25s;
  background-color: #ECEBF0;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 0.5rem;
  flex: 0 0 auto;
  height: 1.2rem;
  width: 1.2rem;
  content: "";
}
form input[type=checkbox] + label:hover, form input[type=checkbox] + .label:hover, form input[type=radio] + label:hover, form input[type=radio] + .label:hover {
  cursor: pointer;
}
form input[type=checkbox] + label:before, form input[type=checkbox] + .label:before {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50%;
}
form input[type=checkbox]:checked + label:before, form input[type=checkbox]:checked + .label:before {
  background-image: url("../content/img/icon-checkmark.svg");
  background-size: 75% auto;
}
form input[type=radio] + label:before, form input[type=radio] + .label:before {
  border: 1px solid var(--secondary-color);
  border-radius: 50%;
}
form input[type=radio]:checked + label:before, form input[type=radio]:checked + .label:before {
  background: var(--secondary-color);
}
form textarea {
  padding: 0.5rem 1rem;
  min-height: 2.5rem;
  resize: vertical;
  height: auto;
}
form textarea.load-ckeditor {
  background-color: #FFF;
  min-height: 305px;
  color: #FFF;
}
form select[multiple] {
  padding: 0.5rem 1rem;
}
form select:not([multiple]) {
  background-image: url("../content/img/icon-select-arrow.svg");
  background-position: right 1rem top calc(50% - 0.25rem);
  background-size: auto 1.5rem;
  background-repeat: no-repeat;
}

.cms-layers {
  margin-top: var(--main-padding);
  display: grid;
}
.cms-layers.cms-layers-hidden {
  display: none;
}
.cms-layers::before {
  margin-bottom: calc(var(--main-padding) / 2);
  background-color: var(--secondary-color);
  display: block;
  grid-row: -2;
  content: "";
  width: 100%;
  height: 1px;
  opacity: 0.125;
}
.cms-layers .add-layer.hidden {
  display: none;
}
.cms-layers .content-grid {
  color: #FFF;
  box-sizing: border-box;
}
.cms-layers .layer-table-container {
  padding: calc(var(--main-padding) / 1.5);
  background-color: #2E2E2D;
}
.cms-layers .layer-table span:not(.visible) {
  display: none;
}
.cms-layers section {
  transition: transform 0.5s cubic-bezier(0.15, 1, 0.3, 1), opacity 0.25s;
  will-change: transform, opacity;
  backface-visibility: hidden;
  pointer-events: none;
  grid-area: 1/1;
  opacity: 0;
}
.cms-layers section.active {
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
  opacity: 1;
}
.cms-layers .layer-icon .icon-container {
  display: flex;
  align-items: center;
}
.cms-layers .layer-icon .icon-container img {
  width: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  object-fit: contain;
  background-color: #ECEBF0;
  border: 0.125rem solid #ECEBF0;
}

.layer-overview {
  transform: translate3d(-10%, 0, 0);
}

.layer-modify {
  transform: translate3d(10%, 0, 0);
}
.layer-modify .layer-form {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
.layer-modify .layer-buttons {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column: span 5;
  justify-content: space-between;
  gap: 1rem;
}
.layer-modify .layer-buttons .layer-back {
  display: flex;
  align-items: center;
}
.layer-modify .layer-buttons .layer-back:before {
  content: "";
  display: flex;
  width: 0.5rem;
  height: 1rem;
  background-image: url("../content/img/icon-back.svg");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.7rem;
}
.layer-modify .layer-buttons .layer-back:hover {
  text-decoration: underline;
}
.layer-modify .layer-buttons .message {
  grid-column: span 2;
  text-align: right;
}
.layer-modify .inline-button {
  margin-left: auto;
}
.layer-modify .small-button {
  margin-left: 1rem;
}
.layer-modify .column {
  padding: calc(var(--main-padding) / 1.5);
  box-sizing: border-box;
}
.layer-modify .column:first-of-type {
  grid-column: span 2;
  background-color: #FFF;
}
.layer-modify .column:last-of-type {
  grid-column: span 3;
  background-color: var(--secondary-color);
}
.layer-modify .main-fields,
.layer-modify .extra-fields {
  display: none;
  flex-direction: column;
}
.layer-modify .main-fields.visible,
.layer-modify .extra-fields.visible {
  display: flex;
}
.layer-modify .extra-fields label, .layer-modify .extra-fields form .label, form .layer-modify .extra-fields .label,
.layer-modify .extra-fields legend {
  color: #FFF;
}

:root {
  --lb-padding: 5rem;
}

body.lightbox-open {
  overflow-y: hidden;
}
body.lightbox-open:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 15;
  opacity: 0.75;
}

.lightbox {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 20;
  padding: var(--lb-padding);
  box-sizing: border-box;
}
.lightbox-inner {
  width: 100%;
  height: 100%;
}
.lightbox-inner iframe {
  width: inherit;
  height: inherit;
}
.lightbox-close {
  width: var(--lb-padding);
  height: var(--lb-padding);
  font-size: 0;
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}
.lightbox-close:after {
  content: url("../content/img/icon-close.svg");
  height: auto;
  width: 35%;
}

.cms-nav-container {
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: var(--nav-width);
  background-color: var(--secondary-color);
  box-sizing: border-box;
  user-select: none;
  overflow-y: scroll;
  scrollbar-width: none;
}
.cms-nav-container a.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 2rem;
}
.cms-nav-container a.logo img {
  width: calc(var(--nav-width) / 2);
  object-fit: contain;
}
.cms-nav-container .nav-block:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.cms-nav-container .nav-block a {
  transition: color 0.25s;
}
.cms-nav-container .nav-block a:hover, .cms-nav-container .nav-block a.active {
  color: var(--main-color);
}
.cms-nav-container .nav-label {
  display: flex;
  padding: 0.5rem 2rem;
  color: #FFF;
  font-weight: 700;
  font-family: serenity, sans-serif;
  font-size: 1.3em;
}
.cms-nav-container .nav-label.active {
  border-left: 4px solid var(--main-color);
  padding-left: calc(2rem - 4px);
  color: var(--main-color);
}
.cms-nav-container .nav-label svg {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 1rem;
}
.cms-nav-container .nav-submenu {
  list-style-type: none;
  margin-left: 4.4rem;
  font-size: 0.9em;
}
.cms-nav-container .nav-submenu li {
  display: flex;
}
.cms-nav-container .nav-submenu a {
  color: #FFF;
  width: 100%;
  padding: 0.4rem 0.4rem 0.4rem 0;
  line-height: 1.2em;
  position: relative;
}
.cms-nav-container .nav-submenu a:after {
  width: var(--nav-width);
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  content: "";
}
.cms-nav-container::-webkit-scrollbar {
  display: none;
}
.cms-nav-container .cms-version-indicator {
  padding: 0.25rem calc(2rem - 4px);
  padding-bottom: 1rem;
  box-sizing: border-box;
  margin-top: auto;
  color: #FFF;
  opacity: 0.25;
  width: 100%;
  line-height: 1.8em;
}

@media (max-width: 1000px) {
  .cms-nav-container {
    transition: transform 0.25s;
    font-size: 1.2rem;
  }
  .cms-nav-container:where(:not(.open)) {
    transform: translateX(-100%);
  }
}
.cms-sidebar {
  top: calc(var(--topbar-height) + var(--main-padding));
  right: 0;
  width: 100%;
  position: sticky;
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 2rem 1rem;
  user-select: none;
}
.cms-sidebar .button {
  margin-right: auto;
}
.cms-sidebar .button.selected-action {
  display: none;
}
.cms-sidebar .message {
  margin-right: 2rem;
}
.cms-sidebar .sidebar-text {
  background-color: #FFF;
  color: #000;
  padding: calc(var(--main-padding) / 1.5);
  user-select: text;
}
.cms-sidebar .sidebar-block {
  display: flex;
  flex-direction: column;
  padding: calc(var(--main-padding) / 1.5);
  color: #FFF;
}
.cms-sidebar .sidebar-block.blue {
  background-color: var(--secondary-color);
}
.cms-sidebar .sidebar-block.darkgrey {
  background-color: #2E2E2D;
}
.cms-sidebar .sidebar-block.darkgrey a {
  color: var(--main-color);
}
.cms-sidebar .block-title {
  display: flex;
  align-items: center;
  font-family: serenity, sans-serif;
  font-size: 1.4rem;
}
.cms-sidebar .block-title svg {
  display: flex;
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
}
.cms-sidebar .block-list {
  list-style-type: none;
  margin-top: 1rem;
}

.cms-page-image-fields .field button.open-lightbox {
  margin-left: auto;
}

.table-container {
  background-color: #FFF;
  padding: calc(var(--main-padding) / 1.5) calc(var(--main-padding) / 3);
  box-sizing: border-box;
  overflow-x: auto;
  width: 100%;
}

.cms-table {
  width: auto;
  min-width: 100%;
  border-collapse: collapse;
}
.cms-table.filtering tbody tr {
  display: none;
}
.cms-table.filtering tbody tr.matches-filter {
  display: table-row;
}
.cms-table.sortable, .cms-table[data-dbtable=cms_users] {
  user-select: none;
}
.cms-table.sortable:not(.dragging).white tbody tr:hover {
  background-color: #636362;
}
.cms-table.sortable:not(.dragging) tbody tr {
  cursor: move;
}
.cms-table.sortable:not(.dragging) tbody tr:hover {
  background-color: #ECEBF0;
}
.cms-table.sortable.white .sortable-ghost {
  background-color: #636362;
}
.cms-table.sortable tbody tr {
  transition: background-color 0.25s;
}
.cms-table .select-row {
  appearance: checkbox;
}
.cms-table th {
  text-align: left;
  color: var(--main-color);
  font-family: serenity, sans-serif;
  line-height: 1em;
  font-size: 1.2rem;
}
.cms-table th {
  padding: 0 0.75rem 0.75rem;
}
.cms-table th:first-of-type {
  padding-left: calc(var(--main-padding) / 3);
}
.cms-table th:last-of-type {
  padding-right: calc(var(--main-padding) / 3);
}
.cms-table td {
  border-bottom: 1px solid rgba(64, 91, 133, 0.2);
}
.cms-table td:not(.icon) {
  padding: 0.75rem;
}
.cms-table td.nowrap {
  white-space: nowrap;
}
.cms-table td:first-of-type {
  padding-left: calc(var(--main-padding) / 3);
}
.cms-table td:last-of-type {
  padding-right: calc(var(--main-padding) / 3);
}
.cms-table td.checkbox {
  width: 1rem;
}
.cms-table td.icon {
  width: 10px;
  color: var(--secondary-color);
  text-align: right;
}
.cms-table td.icon a {
  color: inherit;
}
.cms-table td.icon a,
.cms-table td.icon button {
  transition: color 0.25s;
  padding: 0.75rem;
}
.cms-table td.icon a:hover,
.cms-table td.icon button:hover {
  color: #ff5600;
}
.cms-table td.icon svg {
  width: 1.4rem;
  height: 1.4rem;
}
.cms-table.white {
  color: #FFF;
}
.cms-table.white td.icon {
  color: #FFF;
}
.cms-table .switch-visibility .check,
.cms-table .switch-layer-visibility .check,
.cms-table .switch-processed .check {
  display: none;
}
.cms-table .switch-visibility .disabled,
.cms-table .switch-layer-visibility .disabled,
.cms-table .switch-processed .disabled {
  display: flex;
}
.cms-table .switch-visibility.visible .check,
.cms-table .switch-layer-visibility.visible .check,
.cms-table .switch-processed.visible .check {
  display: flex;
}
.cms-table .switch-visibility.visible .disabled,
.cms-table .switch-layer-visibility.visible .disabled,
.cms-table .switch-processed.visible .disabled {
  display: none;
}
.cms-table.expandable-table tbody td:first-of-type {
  white-space: nowrap;
}
.cms-table.expandable-table tbody td.expandable-column {
  width: 100%;
}
.cms-table.expandable-table ul {
  display: flex;
  flex-wrap: wrap;
  max-height: 1.4em;
  overflow-y: hidden;
  list-style-type: none;
  line-height: 1.75em;
  font-size: 0.8rem;
}
.cms-table.expandable-table li {
  margin-right: 0.75rem;
}
.cms-table.expandable-table li strong {
  font-size: inherit;
  font-size: 1rem;
}
.cms-table.expandable-table .expandable-column.expanded ul {
  flex-direction: column;
  font-size: 1rem;
  max-height: none;
  overflow-y: auto;
}
.cms-table.expandable-table .expandable-column.expanded li {
  display: block;
}
.cms-table.expandable-table .expandable-column.expanded li strong {
  font-size: 1.22rem;
}
.cms-table.expandable-table button.expand-list {
  color: var(--main-color);
  font-size: 0.8rem;
}

.sortable-ghost {
  background-color: #ECEBF0;
  cursor: move;
}

.topbar {
  position: fixed;
  display: flex;
  left: var(--nav-width);
  background-color: #FFF;
  height: var(--topbar-height);
  width: calc(100% - var(--nav-width));
  padding-right: 2rem;
  box-sizing: border-box;
  user-select: none;
  z-index: 10;
}
.topbar .back-button {
  width: var(--main-padding);
}
.topbar .back-button svg {
  width: 0.7rem;
}
.topbar .back-button span {
  line-height: 0;
  font-size: 0;
}
.topbar ul {
  display: flex;
  margin-left: auto;
  list-style-type: none;
  gap: 2rem;
}
.topbar ul li {
  display: flex;
}
.topbar a,
.topbar button:where(:not(.menu-toggle)) {
  justify-content: center;
  align-items: center;
  display: flex;
  color: #636362;
  gap: 1rem;
  transition: color 0.25s;
}
.topbar a:hover, .topbar a.active,
.topbar button:where(:not(.menu-toggle)):hover,
.topbar button:where(:not(.menu-toggle)).active {
  color: var(--main-color);
}
.topbar a.back-button,
.topbar button:where(:not(.menu-toggle)).back-button {
  gap: 0;
}
.topbar svg {
  overflow: visible;
  width: 1.4rem;
  height: auto;
}
.topbar svg * {
  transition: fill 0.25s;
}
.topbar .theme-toggle {
  grid-template-areas: "text icon";
  display: grid;
}
.topbar .theme-toggle[data-cms-theme-dark=false] svg.theme-toggle-icon-light {
  display: block;
}
.topbar .theme-toggle[data-cms-theme-dark=true] svg.theme-toggle-icon-dark {
  display: block;
}
.topbar .theme-toggle svg {
  grid-area: icon;
  display: none;
}
.topbar .menu-toggle {
  padding: 0 var(--main-padding);
  justify-content: center;
  box-sizing: content-box;
  align-items: center;
  position: relative;
  display: none;
  font-size: 0;
  width: 2rem;
  z-index: 4;
}
.topbar .menu-toggle:focus-visible, .topbar .menu-toggle:active {
  outline: none;
}
.topbar .menu-toggle.open::before {
  animation: line1-open 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25) 1 forwards;
}
.topbar .menu-toggle.open span {
  opacity: 0;
}
.topbar .menu-toggle.open::after {
  animation: line2-open 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25) 1 forwards;
}
.topbar .menu-toggle.closed::before {
  animation: line1-closed 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25) 1 forwards;
}
.topbar .menu-toggle.closed::after {
  animation: line2-closed 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25) 1 forwards;
}
.topbar .menu-toggle::before, .topbar .menu-toggle::after, .topbar .menu-toggle span {
  background: currentColor;
  pointer-events: none;
  position: absolute;
  width: 2rem;
  height: 2px;
  content: "";
  right: 0;
  left: 50%;
}
.topbar .menu-toggle::before {
  transform: translate(-50%, -0.5rem);
  transform-origin: 50%;
}
.topbar .menu-toggle::after {
  transform: translate(-50%, 0.5rem);
  transform-origin: 50%;
}
.topbar .menu-toggle span {
  transform: translate(-50%, 0);
  transition: opacity 0.25s;
}

@media (max-width: 1000px) {
  .topbar {
    grid-template-areas: "menutoggle pagetitle buttons";
    grid-template-columns: max-content max-content 1fr;
    display: grid;
    transition: transform 0.25s;
    width: 100%;
    left: 0;
  }
  .topbar .menu-toggle {
    grid-area: menutoggle;
    display: flex;
  }
  .topbar .back-button, .topbar .page-title {
    grid-area: pagetitle;
  }
  .topbar .back-button {
    justify-content: flex-start;
    cursor: pointer;
    width: 100%;
    z-index: 2;
  }
  .topbar .back-button + .page-title {
    padding-left: 1.7rem;
    cursor: pointer;
  }
  .topbar .page-title {
    z-index: 1;
  }
  ul {
    grid-area: buttons;
  }
}
.dashboard {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  align-content: start;
  display: grid;
  gap: 2rem;
}
.dashboard > h2 {
  grid-column: 1/-1;
}
.dashboard section {
  transition: transform 0.25s;
  transform-origin: 50% 100%;
  background-color: #FFF;
  isolation: isolate;
  position: relative;
  display: flex;
}
.dashboard section::after {
  transition: opacity 0.25s, transform 0.25s;
  box-shadow: 0 0.5em 0.75em rgba(0, 0, 0, 0.125);
  position: absolute;
  content: "";
  z-index: -1;
  opacity: 0;
  inset: 0;
}
.dashboard section:hover {
  transform: scale(1.025) translateY(-0.25em);
}
.dashboard section:hover::after {
  opacity: 1;
}
.dashboard section > a, .dashboard section button {
  text-align: left;
  color: inherit;
  padding: 2rem;
}
.dashboard section p a {
  white-space: nowrap;
  color: var(--main-color);
}
.dashboard section p a:hover {
  text-decoration: underline;
}
.dashboard section button {
  user-select: auto;
}

@media (max-width: 1400px) {
  .dashboard {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 800px) {
  .dashboard {
    grid-template-columns: 1fr;
  }
}
.form-modify-fields {
  margin-top: 2rem;
}

main.login {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  flex-direction: column;
}
main.login aside {
  display: none;
  flex-direction: column;
  background-color: var(--secondary-color);
  height: 100%;
  color: #FFF;
  box-sizing: border-box;
  padding: var(--main-padding);
}
main.login aside h1 {
  color: var(--main-color);
}
main.login .cms-version {
  font-size: 0.7rem;
  line-height: 2em;
  opacity: 0.5;
}
main.login .cms-intro {
  padding: 2rem 0;
  font-size: 1.2rem;
}
main.login .cms-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--main-padding);
  box-sizing: border-box;
}
main.login .base-login-form {
  background-color: #FFF;
  padding: 4.25rem;
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 100%;
  box-sizing: border-box;
}
main.login .base-login-form .cp-login-logo {
  width: 100%;
  max-width: 14.5rem;
  margin: 0 auto calc(var(--main-padding) / 1.6);
}
main.login .base-login-form .cp-login-logo + fieldset {
  margin-top: 2rem;
}
main.login .base-login-form label, main.login .base-login-form form .label, form main.login .base-login-form .label {
  font-family: inherit;
  font-size: 1.4rem;
  color: var(--secondary-color);
}
main.login .base-login-form label:not(:first-of-type), main.login .base-login-form form .label:not(:first-of-type), form main.login .base-login-form .label:not(:first-of-type) {
  margin-top: 1rem;
}
main.login .base-login-form input {
  background-color: transparent;
  border-bottom: 1px solid black;
  padding: 0;
  width: 100%;
  font-size: 0.97rem;
  color: black;
  height: 45px;
}
main.login .base-login-form input::placeholder {
  color: rgba(0, 0, 0, 0.8);
}
main.login .base-login-form input + input {
  margin-top: 0.25rem;
}
main.login .cp-title-block {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
main.login h1, main.login h2, main.login h3 {
  font-family: inherit;
  font-size: 1.275rem;
  font-weight: 300;
  text-align: center;
  margin: 0;
  color: var(--base-color, var(--main-color));
}
main.login h3 {
  font-size: 1.2rem;
  font-weight: 700;
}
main.login fieldset {
  display: flex;
  flex-direction: column;
  margin: calc(var(--main-padding) / 1.75) 0;
  min-width: 220px;
}
main.login .button {
  width: 100%;
  background-color: var(--base-color, var(--main-color));
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: filter 0.25s, background-color 0.25s;
  padding: 1.5rem;
  font-weight: 400;
  font-size: 1.55rem;
}
main.login .button:after {
  display: none;
}
main.login .button:hover {
  filter: brightness(80%);
}
main.login .forgot-pass-btn {
  margin-top: 1rem;
  text-align: center;
  color: black;
  font-size: 0.95rem;
}
main.login .message {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.9rem;
}
main.login .message.error {
  color: red;
}

.cms-login.cp-using-plan-styling {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 0;
}
.cms-login.cp-using-plan-styling:before {
  content: "";
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
  z-index: -1;
}

input:focus + .password-requirements-popup {
  opacity: 1;
}

.create-strong-password-container {
  position: relative;
}
.create-strong-password-container .password-requirements-popup {
  transition: opacity 0.25s;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 1.5rem), -50%);
  background-color: #FFF;
  filter: drop-shadow(0 0 25px rgba(0, 0, 0, 0.2));
  padding: calc(var(--main-padding) / 3);
}
.create-strong-password-container .password-requirements-popup:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  transform: translate(-100%, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 12px solid #FFF;
  border-left: 0;
}
.create-strong-password-container ul {
  list-style-type: none;
}
.create-strong-password-container li {
  color: red;
  display: grid;
  grid-template-columns: 1.5rem 1fr;
  align-items: center;
  gap: 0.25rem;
  transition: color 0.25s;
}
.create-strong-password-container li .requirement-matched-icon {
  opacity: 0;
}
.create-strong-password-container li .requirement-failed-icon {
  opacity: 1;
}
.create-strong-password-container li .requirement-matched-icon, .create-strong-password-container li .requirement-failed-icon {
  grid-column: 1;
  grid-row: 1;
  transition: opacity 0.25s;
}
.create-strong-password-container li.requirement-completed {
  color: green;
}
.create-strong-password-container li.requirement-completed .requirement-matched-icon {
  opacity: 1;
}
.create-strong-password-container li.requirement-completed .requirement-failed-icon {
  opacity: 0;
}

@media (max-width: 1000px) {
  main.login {
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr;
  }
  main.login .cms-login {
    height: auto;
    grid-row: 1;
  }
  main.login aside {
    height: auto;
    grid-row: 2;
  }
}
main.media {
  user-select: none;
  display: flex;
}
main.media iframe {
  width: 100%;
}

.menu-structure {
  margin-top: 0.5rem;
}
.menu-structure:where(:not(.dragging)) .item:hover {
  background-color: #636362;
}
.menu-structure .sub-items {
  margin-left: 2rem;
}
.menu-structure .menu-item.sortable-chosen > .item {
  background-color: #636362;
}
.menu-structure .edit-form {
  transition: max-height 0.25s;
  background-color: #FFF;
  flex-direction: column;
  box-sizing: border-box;
  pointer-events: none;
  grid-column: span 4;
  overflow: hidden;
  max-height: 0;
  display: flex;
  width: 100%;
  opacity: 0;
}
.menu-structure .edit-form.visible {
  pointer-events: auto;
  opacity: 1;
}
.menu-structure .edit-form fieldset {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}
.menu-structure .edit-form input, .menu-structure .edit-form select {
  color: #000;
}
.menu-structure .edit-form .field {
  margin-bottom: 0;
}
.menu-structure .edit-form .small-button {
  display: flex;
  margin-left: auto;
  margin-top: 0.5rem;
}
.menu-structure .edit-form .menu-icon-edit .inline-button:last-of-type {
  margin-left: auto;
}
.menu-structure .item {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)) auto auto;
  transition: background-color 0.25s;
  background-color: var(--secondary-color);
  color: #FFF;
  margin-bottom: 0.5rem;
  gap: 0 1rem;
  cursor: move;
}
.menu-structure .name, .menu-structure .type {
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}
.menu-structure .edit-menu, .menu-structure .delete-menu {
  display: flex;
  align-items: center;
}
.menu-structure .edit-menu img, .menu-structure .delete-menu img {
  width: 1.5rem;
  height: 1.5rem;
}
.menu-structure .delete-menu {
  padding-right: 1rem;
}

.add-menu fieldset:first-of-type label, .add-menu fieldset:first-of-type form .label, form .add-menu fieldset:first-of-type .label, .page-filters fieldset:first-of-type label, .page-filters fieldset:first-of-type form .label, form .page-filters fieldset:first-of-type .label {
  font-family: serenity, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--main-color);
}
.add-menu fieldset.sidebar-fields, .page-filters fieldset.sidebar-fields {
  background-color: var(--secondary-color);
}
.add-menu fieldset.sidebar-fields label, .add-menu fieldset.sidebar-fields form .label, form .add-menu fieldset.sidebar-fields .label, .page-filters fieldset.sidebar-fields label, .page-filters fieldset.sidebar-fields form .label, form .page-filters fieldset.sidebar-fields .label {
  color: #FFF;
}
.add-menu fieldset.sidebar-fields label:nth-of-type(2), .add-menu fieldset.sidebar-fields form .label:nth-of-type(2), form .add-menu fieldset.sidebar-fields .label:nth-of-type(2), .add-menu fieldset.sidebar-fields label:nth-of-type(3), .add-menu fieldset.sidebar-fields form .label:nth-of-type(3), form .add-menu fieldset.sidebar-fields .label:nth-of-type(3), .page-filters fieldset.sidebar-fields label:nth-of-type(2), .page-filters fieldset.sidebar-fields form .label:nth-of-type(2), form .page-filters fieldset.sidebar-fields .label:nth-of-type(2), .page-filters fieldset.sidebar-fields label:nth-of-type(3), .page-filters fieldset.sidebar-fields form .label:nth-of-type(3), form .page-filters fieldset.sidebar-fields .label:nth-of-type(3) {
  font-size: 1.25em;
  font-weight: 400;
}
.add-menu fieldset.sidebar-fields .prefix span, .page-filters fieldset.sidebar-fields .prefix span {
  color: #FFF;
}
.add-menu input[type=radio] + label, .add-menu form input[type=radio] + .label, form .add-menu input[type=radio] + .label, .page-filters input[type=radio] + label, .page-filters form input[type=radio] + .label, form .page-filters input[type=radio] + .label {
  font-weight: 400;
}
.add-menu input[type=radio] + label:before, .add-menu form input[type=radio] + .label:before, form .add-menu input[type=radio] + .label:before, .page-filters input[type=radio] + label:before, .page-filters form input[type=radio] + .label:before, form .page-filters input[type=radio] + .label:before {
  border: none;
}
.add-menu input[type=radio]:checked + label:before, .add-menu form input[type=radio]:checked + .label:before, form .add-menu input[type=radio]:checked + .label:before, .page-filters input[type=radio]:checked + label:before, .page-filters form input[type=radio]:checked + .label:before, form .page-filters input[type=radio]:checked + .label:before {
  background: var(--main-color);
}

.stats-details {
  padding: calc(var(--main-padding) / 1.5);
  background-color: var(--secondary-color);
  margin-bottom: var(--main-padding);
  box-sizing: border-box;
  color: #FFF;
  display: grid;
  gap: var(--main-padding);
}
.stats-details:not(.empty) {
  grid-template-columns: auto repeat(2, minmax(0, 1fr));
}
.stats-details h2 {
  font-size: 5rem;
  color: inherit;
}
.stats-details strong {
  color: inherit;
}

.form-title {
  margin-bottom: 0.5rem;
}

.stat-row[data-stat=chart], .stat-row[data-stat=realestate-types] {
  --chart-width: 300px;
  display: grid;
}
.stat-row[data-stat=chart]:not(:last-of-type), .stat-row[data-stat=realestate-types]:not(:last-of-type) {
  margin-bottom: var(--main-padding);
}
.stat-row[data-stat=chart] .form-title, .stat-row[data-stat=realestate-types] .form-title {
  grid-row: 1;
  grid-column: span 2;
}
.stat-row[data-stat=chart] .chart-legend, .stat-row[data-stat=realestate-types] .chart-legend {
  display: flex;
  align-items: center;
  background-color: #FFF;
  padding: 2rem;
  box-sizing: border-box;
}
.stat-row[data-stat=chart] ul, .stat-row[data-stat=realestate-types] ul {
  list-style-type: none;
}
.stat-row[data-stat=chart] li, .stat-row[data-stat=realestate-types] li {
  display: flex;
  align-items: center;
}
.stat-row[data-stat=chart] li:not(:last-of-type), .stat-row[data-stat=realestate-types] li:not(:last-of-type) {
  margin-bottom: 0.75rem;
}
.stat-row[data-stat=chart] li .color, .stat-row[data-stat=realestate-types] li .color {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  margin-right: 0.5rem;
}
.stat-row[data-stat=chart].chart-left, .stat-row[data-stat=realestate-types].chart-left {
  grid-template-columns: var(--chart-width) 1fr;
}
.stat-row[data-stat=chart].chart-left .chart-legend, .stat-row[data-stat=realestate-types].chart-left .chart-legend {
  position: relative;
}
.stat-row[data-stat=chart].chart-left .chart-legend::before, .stat-row[data-stat=realestate-types].chart-left .chart-legend::before {
  position: absolute;
  content: "";
  height: 100%;
  width: var(--chart-width);
  left: 0;
  transform: translateX(-100%);
  background-color: inherit;
  clip-path: polygon(50% 50%, 100% 100%, 100% 0);
  z-index: -1;
}
.stat-row[data-stat=chart].chart-right, .stat-row[data-stat=realestate-types].chart-right {
  grid-template-columns: 1fr var(--chart-width);
}
.stat-row[data-stat=chart].chart-right .chart-legend, .stat-row[data-stat=realestate-types].chart-right .chart-legend {
  grid-row: 2;
  grid-column: 1;
  position: relative;
}
.stat-row[data-stat=chart].chart-right .chart-legend::after, .stat-row[data-stat=realestate-types].chart-right .chart-legend::after {
  position: absolute;
  content: "";
  height: 100%;
  width: var(--chart-width);
  right: 0;
  transform: translateX(100%);
  background-color: inherit;
  clip-path: polygon(50% 50%, 0 100%, 0 0);
  z-index: -1;
}
.stat-row[data-stat=chart].chart-right .chart-container, .stat-row[data-stat=realestate-types].chart-right .chart-container {
  grid-row: 2;
  grid-column: 2;
}

.chart-container[data-type=bar] {
  background-color: #FFF;
  padding: calc(var(--main-padding) / 2);
}

.realestate-stats {
  margin-top: var(--main-padding);
}
.realestate-stats h2 {
  margin-bottom: calc(var(--main-padding) / 2);
  font-size: 1.5rem;
}
.realestate-stats .form-title {
  margin-bottom: 1rem;
}
.realestate-stats div + .form-title {
  margin-top: 2rem;
}
.realestate-stats .realestate-units-situation {
  background: #FFF;
  padding: calc(var(--main-padding) / 2);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}
.realestate-stats .realestate-units-situation svg {
  height: auto;
  max-height: 60vh;
}
.realestate-stats .realestate-units-situation svg text, .realestate-stats .realestate-units-situation svg circle {
  pointer-events: none;
}
.realestate-stats .realestate-units-situation svg .realestate-clickable-unit {
  cursor: pointer;
  transition: filter 0.25s;
}
.realestate-stats .realestate-units-situation svg .realestate-clickable-unit:hover {
  filter: brightness(80%);
}
.realestate-stats .realestate-units-situation-popup {
  position: absolute;
  background-color: #FFF;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  padding: calc(var(--main-padding) / 3);
  transform: translate(-50%, calc(-100% - 1rem));
  opacity: 0;
}
.realestate-stats .realestate-units-situation-popup.visible {
  opacity: 1;
}
.realestate-stats .realestate-units-situation-popup .realestate-units-situation-popup-close {
  cursor: pointer;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}
.realestate-stats .realestate-units-situation-popup .realestate-units-situation-popup-close svg {
  fill: currentColor;
  width: 1rem;
  height: 1rem;
}
.realestate-stats .realestate-units-situation-popup .realestate-units-situation-popup-content {
  padding-top: 0.5rem;
}
.realestate-stats .realestate-units-situation-popup p {
  white-space: nowrap;
}
.realestate-stats .realestate-units-situation-popup th {
  font-weight: 700;
  white-space: nowrap;
}
.realestate-stats .realestate-units-situation-popup th, .realestate-stats .realestate-units-situation-popup td {
  text-align: left;
}
.realestate-stats .realestate-units-situation-popup th:not(:last-of-type), .realestate-stats .realestate-units-situation-popup td:not(:last-of-type) {
  padding-right: calc(var(--main-padding) / 2);
}
.realestate-stats .realestate-units-situation-popup:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12.5px 12.5px 0 12.5px;
  border-color: #FFF transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.realestate-stats .stat-row[data-stat=realestate-table] {
  background-color: #FFF;
  padding: calc(var(--main-padding) / 2) calc(var(--main-padding) / 3);
  box-sizing: border-box;
}
.realestate-stats .stat-row[data-stat=realestate-table] table {
  width: 100%;
}
.realestate-stats .stat-row[data-stat=realestate-table] tr.filtering-by-row {
  color: var(--main-color);
}

.main-fields.user-permissions {
  align-self: start;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0;
  max-width: 100%;
  width: auto;
  padding: calc(var(--main-padding) / 1.5);
  box-sizing: border-box;
  margin-top: 2rem;
  background-color: #2E2E2D;
  color: #FFF;
}
.main-fields.user-permissions fieldset {
  margin-bottom: 0;
  width: auto;
}
.main-fields.user-permissions .field {
  margin-bottom: 0;
}
.main-fields.user-permissions label, .main-fields.user-permissions form .label, form .main-fields.user-permissions .label,
.main-fields.user-permissions legend {
  color: inherit;
}
.main-fields.user-permissions legend {
  font-weight: 700;
  color: var(--main-color);
}
.main-fields.user-permissions #admin_page_permissions {
  min-height: 500px;
}

.field.admin-tools {
  display: grid;
  gap: 0.5rem 1rem;
  grid-template-columns: 1fr;
}
.field.admin-tools label, .field.admin-tools form .label, form .field.admin-tools .label {
  padding-bottom: 0;
  grid-column: 1/-1;
}
.field.admin-tools .small-button {
  text-align: center;
}
.field.admin-tools .small-button:first-of-type {
  margin-left: 0;
}
.field.admin-tools .small-button:not(:first-of-type) {
  margin-left: 0;
}
.field.create-password {
  isolation: isolate;
}
.field.create-password input {
  padding-right: 3rem;
}

.alt-button.show-password {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  margin-left: 0;
  padding-inline: 1rem;
  height: 2.5rem;
  font-size: 1rem;
}
.alt-button.show-password svg .show:where(:not(.active)), .alt-button.show-password svg .hide:where(:not(.active)) {
  display: none;
}

.cms-serverinfo-entry {
  display: block;
}
.cms-serverinfo-entry h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.cms-serverinfo-entry th {
  font-weight: 700;
  padding-right: 1rem;
  text-align: left;
  white-space: nowrap;
  width: 225px;
}
.cms-serverinfo-entry + .cms-serverinfo-entry {
  margin-top: calc(var(--main-padding) / 2);
}

.cp-version-table {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--main-padding);
}
.cp-version-table thead tr {
  font-size: 1.35rem;
}
.cp-version-table thead tr td {
  padding-bottom: 0.5rem;
}
.cp-version-table th {
  text-align: left;
  font-weight: 700;
  padding-right: 1em;
}
.cp-version-table + h2 {
  margin-top: calc(var(--main-padding) / 2);
  margin-bottom: calc(var(--main-padding) / 2);
}

.cp-version-form .radio-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.cp-version-form .radio-container .field {
  margin-bottom: 0;
}
.cp-version-form textarea {
  padding: 1.25rem 1.5rem;
}
.cp-version-form .hidden {
  display: none;
}

.cp-version-table + .changed-pages-parent {
  margin-top: 2rem;
}

.changed-pages-parent h2 {
  margin-bottom: 1rem;
}
.changed-pages-parent + .changed-pages-parent {
  margin-top: 2rem;
}

.changed-page-container + .changed-page-container {
  margin-top: 1rem;
}
.changed-page-container.opened .changed-page-title svg {
  transform: rotate(-180deg);
}
.changed-page-container svg {
  transition: transform 0.25s;
}

.changed-page-header {
  display: grid;
  grid-template-columns: 1fr auto;
}

.changed-page-rollback {
  height: 100%;
  width: auto;
  aspect-ratio: 1/1;
  background-color: #3d3d3c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  box-sizing: border-box;
  transition: color 0.25s;
  cursor: pointer;
}
.changed-page-rollback svg {
  width: 100%;
  height: 100%;
}
.changed-page-rollback:hover {
  background-color: #232323;
}

.changed-page-title {
  background-color: #636362;
  padding: 1rem 2rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFF;
}

.changed-page-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #3d3d3c;
  padding: var(--main-padding);
  box-sizing: border-box;
}
.changed-page-content ul {
  margin-left: 1em;
}
.changed-page-content del {
  background-color: rgba(255, 0, 0, 0.25);
  text-decoration: line-through;
}
.changed-page-content ins {
  background-color: rgba(0, 255, 0, 0.25);
}

.cp-feedback-table .feedback-processed {
  display: none;
}
.cp-feedback-table.show-processed-feedback .feedback-processed {
  display: table-row;
}

.cp-preview-page {
  color: var(--main-color);
  transition: color 0.25s;
}
.cp-preview-page:hover {
  color: var(--main-color-alt);
}

:root {
  color-scheme: light dark;
}
:root.theme-light {
  color-scheme: light;
}
:root.theme-dark {
  color-scheme: dark;
}

@media (prefers-color-scheme: dark) {
  html:where(:not(.theme-light)) body {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) h1 {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .topbar {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .topbar a, html:where(:not(.theme-light)) .topbar button {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .topbar a:hover, html:where(:not(.theme-light)) .topbar a.active,
  html:where(:not(.theme-light)) .topbar button:where(:not(.menu-toggle)):hover,
  html:where(:not(.theme-light)) .topbar button:where(:not(.menu-toggle)).active {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .dashboard section {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .dashboard section p a {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .cms-table.sortable:not(.dragging).white tbody tr:hover {
    background-color: rgba(236, 235, 240, 0.25);
  }
  html:where(:not(.theme-light)) .cms-table.sortable:not(.dragging) tbody tr:hover {
    background-color: rgba(236, 235, 240, 0.25);
  }
  html:where(:not(.theme-light)) .cms-table.expandable-table b, html:where(:not(.theme-light)) .cms-table.expandable-table strong, html:where(:not(.theme-light)) .cms-table.expandable-table h3, html:where(:not(.theme-light)) .cms-table.expandable-table button.expand-list {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .cms-table.white {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .cms-table th {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .cms-table td {
    border-bottom-color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .table-container {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .table-container td.icon a, html:where(:not(.theme-light)) .table-container td.icon button {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .cms-layers::before {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .cms-layers .content-grid {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .cms-layers .layer-table-container {
    background-color: #2E2E2D;
  }
  html:where(:not(.theme-light)) .cms-layers .column:first-of-type {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .cms-layers .column:last-of-type {
    background-color: var(--secondary-color-dark);
  }
  html:where(:not(.theme-light)) .cms-layers .extra-fields label, html:where(:not(.theme-light)) .cms-layers .extra-fields form .label, form html:where(:not(.theme-light)) .cms-layers .extra-fields .label,
  html:where(:not(.theme-light)) .cms-layers .extra-fields legend {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .cms-layers .content-grid {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .cms-layers .layer-table-container {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .message.success {
    color: var(--secondary-color-alt);
  }
  html:where(:not(.theme-light)) .message.error {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) form .base-fields {
    background-color: var(--secondary-color-dark);
  }
  html:where(:not(.theme-light)) form .sidebar-fields,
  html:where(:not(.theme-light)) form .custom-fields {
    background-color: #494949;
    color: #494949;
  }
  html:where(:not(.theme-light)) form .sidebar-fields label, html:where(:not(.theme-light)) form .sidebar-fields .label, html:where(:not(.theme-light)) form .sidebar-fields legend,
  html:where(:not(.theme-light)) form .custom-fields label,
  html:where(:not(.theme-light)) form .custom-fields .label,
  html:where(:not(.theme-light)) form .custom-fields legend {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) form .grey-fields input, html:where(:not(.theme-light)) form .custom-fields input,
  html:where(:not(.theme-light)) form .grey-fields textarea,
  html:where(:not(.theme-light)) form .custom-fields textarea,
  html:where(:not(.theme-light)) form .grey-fields select,
  html:where(:not(.theme-light)) form .custom-fields select {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) form .field.icon input[type=checkbox]:checked + label, html:where(:not(.theme-light)) form .field.icon input[type=checkbox]:checked + .label, html:where(:not(.theme-light)) form .field.icon input[type=radio]:checked + label, html:where(:not(.theme-light)) form .field.icon input[type=radio]:checked + .label {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) form .field.icon input[type=checkbox] + label:before, html:where(:not(.theme-light)) form .field.icon input[type=checkbox] + .label:before, html:where(:not(.theme-light)) form .field.icon input[type=radio] + label:before, html:where(:not(.theme-light)) form .field.icon input[type=radio] + .label:before {
    filter: invert(0.9);
  }
  html:where(:not(.theme-light)) form .prefix span {
    color: rgba(236, 235, 240, 0.5);
  }
  html:where(:not(.theme-light)) form .slug-container p {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) form label, html:where(:not(.theme-light)) form .label,
  html:where(:not(.theme-light)) form legend {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) form small {
    color: rgba(236, 235, 240, 0.5);
  }
  html:where(:not(.theme-light)) form input,
  html:where(:not(.theme-light)) form select {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) form textarea, html:where(:not(.theme-light)) form select {
    background-color: #ECEBF0;
    color: #000;
  }
  html:where(:not(.theme-light)) form input[type=checkbox]:checked + label:before, html:where(:not(.theme-light)) form input[type=checkbox]:checked + .label:before, html:where(:not(.theme-light)) form input[type=radio]:checked + label:before, html:where(:not(.theme-light)) form input[type=radio]:checked + .label:before {
    background-color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) form input[type=checkbox] + label:before, html:where(:not(.theme-light)) form input[type=checkbox] + .label:before, html:where(:not(.theme-light)) form input[type=radio] + label:before, html:where(:not(.theme-light)) form input[type=radio] + .label:before {
    border-color: #2E2E2D;
  }
  html:where(:not(.theme-light)) .lang-switcher .small-button.active {
    background-color: var(--secondary-color-dark);
  }
  html:where(:not(.theme-light)) .inline-button.clear-field {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .small-button {
    background-color: var(--secondary-color-dark);
  }
  html:where(:not(.theme-light)) .alt-button {
    color: rgba(236, 235, 240, 0.5);
  }
  html:where(:not(.theme-light)) .alt-button:hover {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .back-button {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .cms-nav-container {
    background-color: var(--secondary-color-dark);
  }
  html:where(:not(.theme-light)) .cms-nav-container .cms-version-indicator {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) aside.faq {
    background-color: #3b3b3a;
    box-shadow: 0 0 1rem 0 #2E2E2D;
  }
  html:where(:not(.theme-light)) aside.faq .faq-title {
    color: var(--secondary-color-alt);
  }
  html:where(:not(.theme-light)) aside.faq .faq-question strong {
    color: #FFF;
  }
  html:where(:not(.theme-light)) aside.faq .faq-question .title {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) aside.faq .faq-question:hover .title, html:where(:not(.theme-light)) aside.faq .faq-question.active .title {
    background-color: var(--secondary-color);
  }
  html:where(:not(.theme-light)) aside.faq .faq-question:hover .title strong, html:where(:not(.theme-light)) aside.faq .faq-question.active .title strong {
    color: #FFF;
  }
  html:where(:not(.theme-light)) .cms-sidebar .sidebar-block {
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .cms-sidebar .sidebar-block.blue {
    background-color: var(--secondary-color-dark);
  }
  html:where(:not(.theme-light)) .cms-sidebar .sidebar-block.darkgrey {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .cms-sidebar .sidebar-block.darkgrey a {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .cms-sidebar .sidebar-text {
    background-color: #494949;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .cms-sidebar .sidebar-text h3,
  html:where(:not(.theme-light)) .cms-sidebar .sidebar-text b,
  html:where(:not(.theme-light)) .cms-sidebar .sidebar-text strong {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .menu-structure .item {
    background-color: var(--secondary-color-dark);
  }
  html:where(:not(.theme-light)) .menu-structure .item:hover {
    background-color: var(--secondary-color);
  }
  html:where(:not(.theme-light)) .menu-structure .edit-form {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .menu-structure .edit-form input, html:where(:not(.theme-light)) .menu-structure .edit-form select {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .add-menu fieldset.sidebar-fields, html:where(:not(.theme-light)) .page-filters fieldset.sidebar-fields {
    background-color: var(--secondary-color-dark);
  }
  html:where(:not(.theme-light)) .add-menu fieldset.sidebar-fields input,
  html:where(:not(.theme-light)) .add-menu fieldset.sidebar-fields textarea,
  html:where(:not(.theme-light)) .add-menu fieldset.sidebar-fields select, html:where(:not(.theme-light)) .page-filters fieldset.sidebar-fields input,
  html:where(:not(.theme-light)) .page-filters fieldset.sidebar-fields textarea,
  html:where(:not(.theme-light)) .page-filters fieldset.sidebar-fields select {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .add-menu fieldset select, html:where(:not(.theme-light)) .page-filters fieldset select {
    background-color: #494949;
    color: #FFF;
  }
  html:where(:not(.theme-light)) .layer-modify .layer-buttons .layer-back:before {
    background-image: url("../content/img/icon-back-light.svg");
  }
  html:where(:not(.theme-light)) .form-title h3, html:where(:not(.theme-light)) .form-title b, html:where(:not(.theme-light)) .form-title strong {
    color: var(--main-color-alt);
  }
  html:where(:not(.theme-light)) .stats-details {
    background-color: var(--secondary-color-dark);
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .stat-row[data-stat=chart] .chart-legend, html:where(:not(.theme-light)) .stat-row[data-stat=realestate-types] .chart-legend {
    background-color: #494949;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .chart-container[data-type=bar] {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .realestate-stats .realestate-units-situation {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .realestate-stats .realestate-units-situation-popup {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .realestate-stats .stat-row[data-stat=realestate-table] {
    background-color: #494949;
  }
  html:where(:not(.theme-light)) .main-fields.user-permissions {
    background-color: #494949;
    color: #ECEBF0;
  }
  html:where(:not(.theme-light)) .main-fields.user-permissions legend {
    color: var(--main-color-alt);
  }
}
@media (prefers-color-scheme: light) {
  html:where(.theme-dark) body {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(.theme-dark) h1 {
    color: #ECEBF0;
  }
  html:where(.theme-dark) .topbar {
    background-color: #494949;
  }
  html:where(.theme-dark) .topbar a, html:where(.theme-dark) .topbar button {
    color: #ECEBF0;
  }
  html:where(.theme-dark) .topbar a:hover, html:where(.theme-dark) .topbar a.active,
  html:where(.theme-dark) .topbar button:where(:not(.menu-toggle)):hover,
  html:where(.theme-dark) .topbar button:where(:not(.menu-toggle)).active {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .dashboard section {
    background-color: #494949;
  }
  html:where(.theme-dark) .dashboard section p a {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .cms-table.sortable:not(.dragging).white tbody tr:hover {
    background-color: rgba(236, 235, 240, 0.25);
  }
  html:where(.theme-dark) .cms-table.sortable:not(.dragging) tbody tr:hover {
    background-color: rgba(236, 235, 240, 0.25);
  }
  html:where(.theme-dark) .cms-table.expandable-table b, html:where(.theme-dark) .cms-table.expandable-table strong, html:where(.theme-dark) .cms-table.expandable-table h3, html:where(.theme-dark) .cms-table.expandable-table button.expand-list {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .cms-table.white {
    background-color: #494949;
  }
  html:where(.theme-dark) .cms-table th {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .cms-table td {
    border-bottom-color: #ECEBF0;
  }
  html:where(.theme-dark) .table-container {
    background-color: #494949;
  }
  html:where(.theme-dark) .table-container td.icon a, html:where(.theme-dark) .table-container td.icon button {
    color: #ECEBF0;
  }
  html:where(.theme-dark) .cms-layers::before {
    background-color: #494949;
  }
  html:where(.theme-dark) .cms-layers .content-grid {
    color: #ECEBF0;
  }
  html:where(.theme-dark) .cms-layers .layer-table-container {
    background-color: #2E2E2D;
  }
  html:where(.theme-dark) .cms-layers .column:first-of-type {
    background-color: #494949;
  }
  html:where(.theme-dark) .cms-layers .column:last-of-type {
    background-color: var(--secondary-color-dark);
  }
  html:where(.theme-dark) .cms-layers .extra-fields label, html:where(.theme-dark) .cms-layers .extra-fields form .label, form html:where(.theme-dark) .cms-layers .extra-fields .label,
  html:where(.theme-dark) .cms-layers .extra-fields legend {
    color: #ECEBF0;
  }
  html:where(.theme-dark) .cms-layers .content-grid {
    color: #ECEBF0;
  }
  html:where(.theme-dark) .cms-layers .layer-table-container {
    background-color: #494949;
  }
  html:where(.theme-dark) .message.success {
    color: var(--secondary-color-alt);
  }
  html:where(.theme-dark) .message.error {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) form .base-fields {
    background-color: var(--secondary-color-dark);
  }
  html:where(.theme-dark) form .sidebar-fields,
  html:where(.theme-dark) form .custom-fields {
    background-color: #494949;
    color: #494949;
  }
  html:where(.theme-dark) form .sidebar-fields label, html:where(.theme-dark) form .sidebar-fields .label, html:where(.theme-dark) form .sidebar-fields legend,
  html:where(.theme-dark) form .custom-fields label,
  html:where(.theme-dark) form .custom-fields .label,
  html:where(.theme-dark) form .custom-fields legend {
    color: #ECEBF0;
  }
  html:where(.theme-dark) form .grey-fields input, html:where(.theme-dark) form .custom-fields input,
  html:where(.theme-dark) form .grey-fields textarea,
  html:where(.theme-dark) form .custom-fields textarea,
  html:where(.theme-dark) form .grey-fields select,
  html:where(.theme-dark) form .custom-fields select {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(.theme-dark) form .field.icon input[type=checkbox]:checked + label, html:where(.theme-dark) form .field.icon input[type=checkbox]:checked + .label, html:where(.theme-dark) form .field.icon input[type=radio]:checked + label, html:where(.theme-dark) form .field.icon input[type=radio]:checked + .label {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) form .field.icon input[type=checkbox] + label:before, html:where(.theme-dark) form .field.icon input[type=checkbox] + .label:before, html:where(.theme-dark) form .field.icon input[type=radio] + label:before, html:where(.theme-dark) form .field.icon input[type=radio] + .label:before {
    filter: invert(0.9);
  }
  html:where(.theme-dark) form .prefix span {
    color: rgba(236, 235, 240, 0.5);
  }
  html:where(.theme-dark) form .slug-container p {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(.theme-dark) form label, html:where(.theme-dark) form .label,
  html:where(.theme-dark) form legend {
    color: #ECEBF0;
  }
  html:where(.theme-dark) form small {
    color: rgba(236, 235, 240, 0.5);
  }
  html:where(.theme-dark) form input,
  html:where(.theme-dark) form select {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(.theme-dark) form textarea, html:where(.theme-dark) form select {
    background-color: #ECEBF0;
    color: #000;
  }
  html:where(.theme-dark) form input[type=checkbox]:checked + label:before, html:where(.theme-dark) form input[type=checkbox]:checked + .label:before, html:where(.theme-dark) form input[type=radio]:checked + label:before, html:where(.theme-dark) form input[type=radio]:checked + .label:before {
    background-color: var(--main-color-alt);
  }
  html:where(.theme-dark) form input[type=checkbox] + label:before, html:where(.theme-dark) form input[type=checkbox] + .label:before, html:where(.theme-dark) form input[type=radio] + label:before, html:where(.theme-dark) form input[type=radio] + .label:before {
    border-color: #2E2E2D;
  }
  html:where(.theme-dark) .lang-switcher .small-button.active {
    background-color: var(--secondary-color-dark);
  }
  html:where(.theme-dark) .inline-button.clear-field {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .small-button {
    background-color: var(--secondary-color-dark);
  }
  html:where(.theme-dark) .alt-button {
    color: rgba(236, 235, 240, 0.5);
  }
  html:where(.theme-dark) .alt-button:hover {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .back-button {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .cms-nav-container {
    background-color: var(--secondary-color-dark);
  }
  html:where(.theme-dark) .cms-nav-container .cms-version-indicator {
    color: #ECEBF0;
  }
  html:where(.theme-dark) aside.faq {
    background-color: #3b3b3a;
    box-shadow: 0 0 1rem 0 #2E2E2D;
  }
  html:where(.theme-dark) aside.faq .faq-title {
    color: var(--secondary-color-alt);
  }
  html:where(.theme-dark) aside.faq .faq-question strong {
    color: #FFF;
  }
  html:where(.theme-dark) aside.faq .faq-question .title {
    background-color: #494949;
  }
  html:where(.theme-dark) aside.faq .faq-question:hover .title, html:where(.theme-dark) aside.faq .faq-question.active .title {
    background-color: var(--secondary-color);
  }
  html:where(.theme-dark) aside.faq .faq-question:hover .title strong, html:where(.theme-dark) aside.faq .faq-question.active .title strong {
    color: #FFF;
  }
  html:where(.theme-dark) .cms-sidebar .sidebar-block {
    color: #ECEBF0;
  }
  html:where(.theme-dark) .cms-sidebar .sidebar-block.blue {
    background-color: var(--secondary-color-dark);
  }
  html:where(.theme-dark) .cms-sidebar .sidebar-block.darkgrey {
    background-color: #494949;
  }
  html:where(.theme-dark) .cms-sidebar .sidebar-block.darkgrey a {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .cms-sidebar .sidebar-text {
    background-color: #494949;
    color: #ECEBF0;
  }
  html:where(.theme-dark) .cms-sidebar .sidebar-text h3,
  html:where(.theme-dark) .cms-sidebar .sidebar-text b,
  html:where(.theme-dark) .cms-sidebar .sidebar-text strong {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .menu-structure .item {
    background-color: var(--secondary-color-dark);
  }
  html:where(.theme-dark) .menu-structure .item:hover {
    background-color: var(--secondary-color);
  }
  html:where(.theme-dark) .menu-structure .edit-form {
    background-color: #494949;
  }
  html:where(.theme-dark) .menu-structure .edit-form input, html:where(.theme-dark) .menu-structure .edit-form select {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(.theme-dark) .add-menu fieldset.sidebar-fields, html:where(.theme-dark) .page-filters fieldset.sidebar-fields {
    background-color: var(--secondary-color-dark);
  }
  html:where(.theme-dark) .add-menu fieldset.sidebar-fields input,
  html:where(.theme-dark) .add-menu fieldset.sidebar-fields textarea,
  html:where(.theme-dark) .add-menu fieldset.sidebar-fields select, html:where(.theme-dark) .page-filters fieldset.sidebar-fields input,
  html:where(.theme-dark) .page-filters fieldset.sidebar-fields textarea,
  html:where(.theme-dark) .page-filters fieldset.sidebar-fields select {
    background-color: #2E2E2D;
    color: #ECEBF0;
  }
  html:where(.theme-dark) .add-menu fieldset select, html:where(.theme-dark) .page-filters fieldset select {
    background-color: #494949;
    color: #FFF;
  }
  html:where(.theme-dark) .layer-modify .layer-buttons .layer-back:before {
    background-image: url("../content/img/icon-back-light.svg");
  }
  html:where(.theme-dark) .form-title h3, html:where(.theme-dark) .form-title b, html:where(.theme-dark) .form-title strong {
    color: var(--main-color-alt);
  }
  html:where(.theme-dark) .stats-details {
    background-color: var(--secondary-color-dark);
    color: #ECEBF0;
  }
  html:where(.theme-dark) .stat-row[data-stat=chart] .chart-legend, html:where(.theme-dark) .stat-row[data-stat=realestate-types] .chart-legend {
    background-color: #494949;
    color: #ECEBF0;
  }
  html:where(.theme-dark) .chart-container[data-type=bar] {
    background-color: #494949;
  }
  html:where(.theme-dark) .realestate-stats .realestate-units-situation {
    background-color: #494949;
  }
  html:where(.theme-dark) .realestate-stats .realestate-units-situation-popup {
    background-color: #494949;
  }
  html:where(.theme-dark) .realestate-stats .stat-row[data-stat=realestate-table] {
    background-color: #494949;
  }
  html:where(.theme-dark) .main-fields.user-permissions {
    background-color: #494949;
    color: #ECEBF0;
  }
  html:where(.theme-dark) .main-fields.user-permissions legend {
    color: var(--main-color-alt);
  }
}