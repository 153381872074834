:root {
    --lb-padding: 5rem;
}

body.lightbox-open {
    overflow-y: hidden;
    &:before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: $black;
        z-index: 15;
        opacity: .75;
    }
}

.lightbox {
    position: fixed;
    display: flex;

    width: 100%;
    height: 100%;
    z-index: 20;

    padding: var(--lb-padding);
    box-sizing: border-box;

    &-inner {
        width: 100%;
        height: 100%;

        iframe {
            width: inherit;
            height: inherit;
        }
    }

    &-close {
        width: var(--lb-padding);
        height: var(--lb-padding);

        font-size: 0;
        line-height: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        right: 0;
        top: 0;

        &:after {
            content: url('../content/img/icon-close.svg');
            height: auto;
            width: 35%;
        }
    }
}