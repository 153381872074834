.cms-layers {
    margin-top: var(--main-padding);
    display: grid;

    &.cms-layers-hidden {
        display: none;
    }

    &::before {
        margin-bottom: calc(var(--main-padding) / 2);
        background-color: var(--secondary-color);
        display: block;
        grid-row: -2;
        content: '';
        width: 100%;
        height: 1px;
        opacity: .125;
    }
    
    .add-layer {
        &.hidden {
            display: none;
        }
    }

    .content-grid {
        color: $white;
        box-sizing: border-box;
    }

    .layer-table-container {
        padding: calc(var(--main-padding) / 1.5);
        background-color: $darkgrey;
    }

    .layer-table {
        span {
            &:not(.visible) {
                display: none;
            }
        }
    }

    section {
        transition: transform .5s cubic-bezier(0.15, 1, 0.3, 1), opacity .25s;
        will-change: transform, opacity;
        backface-visibility: hidden;
        pointer-events: none;
        grid-area: 1/1;
        opacity: 0;
        &.active {
            transform: translate3d(0, 0, 0);
            pointer-events: auto;
            opacity: 1;
        }
    }

    .layer-icon .icon-container {
        display: flex;
        align-items: center;

        img {
            width: 1.5rem;
            margin-right: .5rem;
            width: 1.5rem;
            height: 0.75rem;
            margin-right: .5rem;
            object-fit: contain;
            background-color: $lightgrey;
            border: 0.125rem solid $lightgrey;
        }
    }
}

.layer-overview {
    transform: translate3d(-10%, 0, 0);
}

.layer-modify {
    transform: translate3d(10%, 0, 0);
    .layer-form {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }

    .layer-buttons {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-column: span 5;
        justify-content: space-between;
        gap: 1rem;

        .layer-back {
            display: flex;
            align-items: center;

            &:before {
                content: '';
                display: flex;
                width: .5rem;
                height: 1rem;
                background-image: url('../content/img/icon-back.svg');
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: .7rem;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .message {
            grid-column: span 2;
            text-align: right;
        }
    }

    .inline-button {
        margin-left: auto;
    }
    .small-button {
        margin-left: 1rem;
    }

    .column {
        padding: calc(var(--main-padding) / 1.5);
        box-sizing: border-box;

        &:first-of-type {
            grid-column: span 2;
            background-color: $white;
        }

        &:last-of-type {
            grid-column: span 3;
            background-color: var(--secondary-color);
        }
    }

    .main-fields,
    .extra-fields {
        display: none;
        flex-direction: column;

        &.visible {
            display: flex;
        }
    }

    .extra-fields {

        label,
        legend {
            color: $white;
        }
    }
}