//Hide the TinyMCE advertising
.tox .tox-promotion-link {
    display: none !important;
}

.tox-statusbar__branding {
    display: none !important;
}

.tox-tinymce {
    width: 100%;
}

.tox-fullscreen {
    top: calc(var(--topbar-height) + 2rem) !important;
    left: var(--nav-width) !important;
    width: calc(100vw - var(--nav-width)) !important;
    height: calc(100vh - var(--topbar-height) - 2rem) !important;
}

//-----------------------------------------------------
// Start styling the editor
//-----------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

.mce-content-body {
    padding: 20px !important;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    line-height: 1.6em;

    * {
        margin: 0;
    }

    strong {
        font-weight: 500;
    }

    table {
        border-collapse: collapse;
    }

    th {
        font-weight: 700;
    }

    td {
        border: 1px solid black;
    }
}