.field {
    &.admin-tools {
        display: grid;
        gap: .5rem 1rem;

        grid-template-columns: 1fr;

        label {
            padding-bottom: 0;
            grid-column: 1/-1;
        }

        .small-button {
            text-align: center;
            &:first-of-type {
                margin-left: 0;
            }

            &:not(:first-of-type) {
                margin-left: 0;
            }
        }
    }
    &.create-password {
        isolation: isolate;
        input {
            padding-right: 3rem;
        }
    }
}

.alt-button.show-password {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;

    margin-left: 0;
    padding-inline: 1rem;

    height: 2.5rem;
    font-size: 1rem;

    svg {
        .show, .hide {
            &:where(:not(.active)) {
                display: none;
            }
        }
    }
}
.cms-serverinfo-entry {
    display: block;

    h3 {
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }

    th {
        font-weight: 700;
        padding-right: 1rem;
        text-align: left;
        white-space: nowrap;
        width: 225px;
    }

    +.cms-serverinfo-entry {
        margin-top: calc(var(--main-padding) / 2);
    }
}