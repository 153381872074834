@mixin darkmode {
    $grey: darken($grey, 10%);

    body {
        background-color: $darkgrey;
        color: $lightgrey;
    }
    h1 {
        color: $lightgrey;
    }
    .topbar {
        background-color: $grey;
        a, button {
            color: $lightgrey;
        }
        a,
        button:where(:not(.menu-toggle)) {
            &:hover,
            &.active {
                color: var(--main-color-alt);
            }
        }
    }
    .dashboard {
        section {
            background-color: $grey;
            p {
                a {
                    color: var(--main-color-alt);
                }
            }
        }
    }
    .cms-table {
        &.sortable {
            &:not(.dragging) {
                &.white {
                    tbody {
                        tr {
                            &:hover {
                                background-color: rgba($lightgrey, 0.25);
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        &:hover {
                            background-color: rgba($lightgrey, 0.25);
                        }
                    }
                }
            }
        }
        &.expandable-table {    
            b, strong, h3, button.expand-list {
                color: var(--main-color-alt);
            }
        }
        &.white {
            background-color: $grey;
        }
        th {
            color: var(--main-color-alt);
        }
        td {
            border-bottom-color: $lightgrey;
        }
    }
    .table-container {
        background-color: $grey;
        td.icon {
            a, button {
                color: $lightgrey;
            }
        }
    }
    .cms-layers {
        &::before {
            background-color: $grey;
        }
        .content-grid {
            color: $lightgrey;
        }
        .layer-table-container {
            background-color: $darkgrey;
        }
        .column {
            &:first-of-type {
                background-color: $grey;
            }
            &:last-of-type {
                background-color: var(--secondary-color-dark);
            }
        }
        .extra-fields {
            label,
            legend {
                color: $lightgrey;
            }
        }
        .content-grid {
            color: $lightgrey;
        }
        .layer-table-container {
            background-color: $grey;
        }
    }
    .message {
        &.success {
            color: var(--secondary-color-alt);
        }
        &.error {
            color: var(--main-color-alt);
        }
    }    
    form {
        .base-fields {
            background-color: var(--secondary-color-dark);
        }
        .sidebar-fields,
        .custom-fields {
            background-color: $grey;
            color: $grey;
            label, legend {
                color: $lightgrey;
            }
        }
        .grey-fields {
            input,
            textarea,
            select {
                background-color: $darkgrey;
                color: $lightgrey
            }
        }
        .field {
            &.icon {
                input {
                    &[type="checkbox"],
                    &[type="radio"] {
                        &:checked {
                            +label {
                                color: var(--main-color-alt);
                            }
                        }
                        + label {
                            &:before {
                                filter: invert(0.9);
                            }
                        }
                    }
                }
            }
        }
        .prefix {
            span {
                color: rgba($lightgrey, 0.5);       
            }
        }
        .slug-container {
            p {
                background-color: $darkgrey;
                color: $lightgrey
            }
        }
        label,
        legend {
            color: $lightgrey;
        }
        small {
            color: rgba($lightgrey, 0.5);
        }
        input,
        select {
            background-color: $darkgrey;
            color: $lightgrey
        }
        textarea, select {
            background-color: $lightgrey;
            color: $black;
        }
        input {
            &[type="checkbox"],
            &[type="radio"] {
                &:checked {
                    + label {
                        &:before {
                            background-color: var(--main-color-alt);
                        }
                    }
                }
                + label {
                    &:before {
                        border-color: $darkgrey;
                    }
                }
            }
        }
    }
    .lang-switcher {
        .small-button {
            &.active {
                background-color: var(--secondary-color-dark);
            }
        }
    }
    .inline-button {
        &.clear-field {
            color: var(--main-color-alt);
        }
    }
    .small-button {
        background-color: var(--secondary-color-dark);
    }
    .alt-button {
        color: rgba($lightgrey, 0.5);
        &:hover {
            color: var(--main-color-alt);
        }
    }
    .back-button {
        color: var(--main-color-alt);
    }
    //main.login {
    //    aside {
    //        background-color: var(--secondary-color-dark);
    //        color: $lightgrey;
    //    }
    //    .base-login-form, .password-requirements-popup {
    //        background-color: $grey;
    //        color: $lightgrey;
    //        label {
    //            color: $lightgrey;
    //        }
    //        input {
    //            background-color: $darkgrey;
    //            color: $lightgrey
    //        }
    //
    //        &:before {
    //            border-right: 12px solid $grey;
    //
    //        }
    //    }
    //}
    .cms-nav-container {
        background-color: var(--secondary-color-dark);
        .cms-version-indicator {
            color: $lightgrey;
        }
    }
    aside.faq {    
        background-color: lighten($darkgrey, 5%);
        box-shadow: 0 0 1rem 0 $darkgrey;
        .faq-title {
            color: var(--secondary-color-alt);
        }
        .faq-question {
            strong {
                color: $white;
            }
            .title {
                background-color: $grey;
            }
            &:hover,
            &.active {
                .title {
                    background-color: var(--secondary-color);
                    strong {
                        color: $white;
                    }
                }
            }
        }
    }
    .cms-sidebar {
        .sidebar-block {
            color: $lightgrey;
            &.blue {
                background-color: var(--secondary-color-dark);
            }
            &.darkgrey {
                background-color: $grey;
                a {
                    color: var(--main-color-alt);
                }
            }
        }
        .sidebar-text {
            background-color: $grey;
            color: $lightgrey;
            h3,
            b,
            strong {
                color: var(--main-color-alt);
            }
        }
    }
    .menu-structure {
        .item {
            background-color: var(--secondary-color-dark);
            &:hover {
                background-color: var(--secondary-color);
            }
        }
        .edit-form {
            background-color: $grey;
            input, select {
                background-color: $darkgrey;
                color: $lightgrey
            }
        }
    }
    .add-menu, .page-filters {
        fieldset {
            &.sidebar-fields {
                background-color: var(--secondary-color-dark);
                input,
                textarea,
                select {
                    background-color: $darkgrey;
                    color: $lightgrey;
                }
            }
            select {
                background-color: $grey;
                color: $white;
            }
        }
    }
    .layer-modify {
        .layer-buttons {
            .layer-back {
                &:before {
                    background-image: url('../content/img/icon-back-light.svg');
                }
            }
        }
    }
    .form-title {
        h3, b, strong {
            color: var(--main-color-alt);
        }
    }
    .stats-details {
        background-color: var(--secondary-color-dark);
        color: $lightgrey;
    }
    .stat-row[data-stat="chart"], .stat-row[data-stat="realestate-types"] {
        .chart-legend {
            background-color: $grey;
            color: $lightgrey;
        }
    }
    .chart-container[data-type="bar"] {
        background-color: $grey;
    }
    .realestate-stats {
        .realestate-units-situation {
            background-color: $grey;
        }
        .realestate-units-situation-popup {
            background-color: $grey;
        }
        .stat-row[data-stat="realestate-table"] {
            background-color: $grey;
        }
    }
    .main-fields.user-permissions {
        background-color: $grey;
        color: $lightgrey;

        legend {
            color: var(--main-color-alt);
        }
    }
}

:root {
    color-scheme: light dark;
    &.theme-light {
        color-scheme: light;
    }
    &.theme-dark {
        color-scheme: dark;
    }
}

// Use OS setting if light theme not manually activated
@media ( prefers-color-scheme: dark ) {
    html:where(:not(.theme-light)) {
        @include darkmode;
    }
}

// Use manually set dark theme
@media ( prefers-color-scheme: light ) {
    html:where(.theme-dark) {
        @include darkmode;
    }
}