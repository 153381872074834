.message {
    display: none;
    &.success {
        color: var(--secondary-color);
    }
    &.error {
        color: var(--main-color);
    }
}

form {
    h1 {
        margin-bottom: 1rem;
    }

    .slug-container {
        line-height: 1em;

        white-space: nowrap;
        display: flex;

        gap: 1rem 0;

        width: 100%;
        max-width: 45rem;

        p {
            display: flex;
            height: 100%;
            align-items: center;
            background-color: $white;
            padding-left: 1rem;
        }

        input:not([type=checkbox]) {
            margin-bottom: 0;
            padding-left: 0;
        }

        a {
            padding-inline: 1rem;
            align-items: center;
            display: flex;
            color: $white;
            gap: 0.5em;
            &[href="#"] {
                pointer-events: none;
                opacity: 0.5;
            }
            &:hover {
                text-decoration: underline;
            }
            svg {
                height: auto;
                width: 1em;
            }
        }

    }

    label,
    legend {
        justify-content: space-between;
        align-items: center;
        user-select: none;
        display: flex;
        color: $darkgrey;
        font-size: 1.2rem;
    }

    .label {
        @extend label;
    }

    small {
        color: rgba($darkgrey, 0.5);
        margin-left: 0.25rem;
    }

    fieldset {
        &.layer-types {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 0 1rem;
        }

        // &:not(:last-child)
        // &:not(:last-of-type) {
        &:where(:not(:last-child):not(:last-of-type)) {
            margin-bottom: 1rem;
        }

        .personal-data-grid {
            display: grid;
            grid-template-columns: 1fr 175px 1fr;
            gap: 1rem;

            margin-bottom: 1rem;

            .field {
                margin-bottom: 0;
            }
        }

        label,
        legend {
            padding-bottom: 0.5rem;
            width: 100%;
        }
    }

    .base-fields,
    .main-fields,
    .custom-fields {
        width: 100%;
    }

    .main-fields,
    .custom-fields {
        &:empty {
            display: none;
        }
    }

    .base-fields {
        background-color: var(--secondary-color);
        padding: calc(var(--main-padding) / 1.5);
        box-sizing: border-box;
        label,
        legend {
            color: $white;
        }
        small {
            color: rgba($white, 0.5);
        }
    }

    .main-fields {
        &-empty {
            pointer-events: none;
            user-select: none;
            font-size: 1.25em;
            line-height: 1.75em;
            max-width: 45rem;
            text-align: center;
            padding: 1em;
            margin: auto;
        }
        input {
            &[type="checkbox"] {
                +label {
                    &:before {
                        background-color: $white;
                    }
                }
            }
        }
    }

    .custom-fields {
        @extend .grey-fields;

        padding: calc(var(--main-padding) / 1.5);
        margin-top: var(--main-padding);
        box-sizing: border-box;

        .inline-button {
            margin-left: auto;
        }
        .small-button:not(:first-of-type) {
            margin-left: 1rem;
        }
    }

    .cke {
        user-select: none;
        width: 100%;
    }

    .grey-fields {
        background-color: $white;
        input,
        textarea,
        select {
            background-color: $lightgrey;
        }

        input[type="checkbox"]+label:before {
            background-color: $lightgrey;
        }
    }

    .sidebar-fields {
        background-color: $white;
        padding: calc(var(--main-padding) / 1.5);
        box-sizing: border-box;

        label,
        legend {
            color: var(--secondary-color);
            font-family: $serenity;
            font-size: 1.4rem;
            font-weight: 700;
        }

        .inline-button:last-of-type {
            margin-left: auto;
        }

        .small-button {
            display: block;

            &:first-of-type {
                margin-left: auto;
            }

            &:not(:first-of-type) {
                margin-left: 1rem;
            }
        }
    }

    label,
    fieldset {
        width: 100%;
    }

    input,
    select:not([multiple]) {
        height: 2.5rem;
    }

    input,
    textarea,
    select {
        background-color: $white;
        color: black;
    }

    .prefix {
        align-items: center;
        display: flex;
        width: 100%;
        gap: .5rem;
        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
        span {
            color: rgba($darkgrey, 0.5);
            label {
                padding-bottom: 0;
                color: inherit;
                font: inherit;
            }
        }
    }

    .field {
        &:where(:not([hidden])) {
            display: flex;
        }
        flex-wrap: wrap;

        position: relative;

        &:where(:not(:last-of-type)) {
            margin-bottom: 1rem;
        }

        &.alt-fields {
            font-size: .85em;
            width: 100%;
            input {
                padding-inline: 1em;
                height: 2.5em;
            }
        }

        .image-preview {
            display: block;
            width: 100%;
            &[src$=".svg"], &[src$=".png"] {
                background-color: rgba($blue, 0.25);
            }
            &.small {
                object-fit: cover;
                height: 2.5rem;
                width: 2.5rem;
                &[src$=".svg"], &[src$=".png"] {
                    box-sizing: border-box;
                    object-fit: contain;
                    padding: 0.25rem;
                }

                +input {
                    width: calc(100% - 2.5rem);
                }
            }
            ~ {
                .inline-button {
                    &.clear-field {
                        pointer-events: auto;
                        opacity: 1;
                    }
                }
            }
        }

        input, select, textarea {
            &:where(:not(:last-child)) {
                margin-bottom: .5rem;
            }
        }

        &.hidden {
            display: none;
        }

        &.icon {
            input {
                &[type="checkbox"],
                &[type="radio"] {
                    &:checked {
                        +label {
                            color: var(--main-color);
                        }
                    }

                    +label {
                        &:before {
                            background-image: var(--layer-icon);
                            background-color: $lightgrey;
                            background-repeat: no-repeat;
                            background-position: 50%;
                            background-size: contain;

                            border: 0.25rem solid $lightgrey;

                            width: 4rem;
                            height: 2rem;

                            margin-right: 0.75rem;

                            box-sizing: content-box;
                        }
                    }
                }

                &[type="radio"] {
                    +label {
                        &:before {
                            border-radius: 0;
                        }
                    }
                }
            }
        }

        input+select,
        input+textarea {
            margin-top: .5rem;
        }
    }

    input:not([type="checkbox"]),
    input:not([type="radio"]),
    input:not([type="submit"]),
    textarea,
    select {
        width: 100%;
    }

    input:not([type="checkbox"]),
    input:not([type="radio"]),
    input:not([type="submit"]),
    textarea,
    select {
        box-sizing: border-box;
        padding: 0 1rem;
    }

    input {
        &[readonly] {
            cursor: not-allowed;
            ~ {

                .inline-button,
                .small-button {
                    margin-top: .5rem;
                }
            }
        }

        &[type="file"] {
            &::file-selector-button {
                display: none;
            }
        }

        &[type="checkbox"],
        &[type="radio"] {
            pointer-events: none;
            position: absolute;
            opacity: 0;

            &[disabled] {
                + label {
                    opacity: 0.5;
                }
            }

            + label {
                transition: color .25s;
                display: inline-flex;
                align-items: center;
                padding-right: 1.5rem;
                font-weight: 400;
                width: auto;

                &:before {
                    transition: background-color .25s;
                    background-color: $lightgrey;
                    box-sizing: border-box;
                    display: inline-block;
                    margin-right: 0.5rem;
                    flex: 0 0 auto;
                    height: 1.2rem;
                    width: 1.2rem;
                    content: '';
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        &[type="checkbox"] {
            +label {
                &:before {
                    background-repeat: no-repeat;
                    background-size: 100% auto;
                    background-position: 50%;
                }
            }

            &:checked {
                +label {
                    &:before {
                        background-image: url("../content/img/icon-checkmark.svg");
                        background-size: 75% auto;
                    }
                }
            }
        }

        &[type="radio"] {
            +label {
                &:before {
                    border: 1px solid var(--secondary-color);
                    border-radius: 50%;
                }
            }

            &:checked {
                +label {
                    &:before {
                        background: var(--secondary-color);
                    }
                }
            }
        }
    }

    textarea {
        padding: 0.5rem 1rem;
        min-height: 2.5rem;
        resize: vertical;
        height: auto;
        &.load-ckeditor {
            background-color: $white;
            min-height: 305px;
            color: $white;
        }
    }

    select {
        &[multiple] {
            padding: 0.5rem 1rem;
        }

        &:not([multiple]) {
            background-image: url('../content/img/icon-select-arrow.svg');

            background-position: right 1rem top calc(50% - 0.25rem);
            background-size: auto 1.5rem;
            background-repeat: no-repeat;
        }
    }
}