.stats-details {
    padding: calc(var(--main-padding) / 1.5);
    background-color: var(--secondary-color);
    margin-bottom: var(--main-padding);
    box-sizing: border-box;
    color: $white;

    display: grid;

    gap: var(--main-padding);

    &:not(.empty) {
        grid-template-columns: auto repeat(2, minmax(0, 1fr));
    }

    h2 {
        font-size: 5rem;
        color: inherit;
    }

    strong {
        color: inherit;
    }
}

.form-title {
    margin-bottom: .5rem;
}

.stat-row[data-stat="chart"], .stat-row[data-stat="realestate-types"] {
    --chart-width: 300px;
    display: grid;

    &:not(:last-of-type) {
        margin-bottom: var(--main-padding);
    }

    .form-title {
        grid-row: 1;
        grid-column: span 2;
    }

    .chart-legend {
        display: flex;
        align-items: center;
        background-color: $white;
        padding: 2rem;
        box-sizing: border-box;
    }

    ul {
        list-style-type: none;
    }

    li {
        display: flex;
        align-items: center;

        &:not(:last-of-type) {
            margin-bottom: .75rem;
        }

        .color {
            width: 2rem;
            height: 2rem;
            border-radius: 100%;
            margin-right: .5rem;
        }
    }

    &.chart-left {
        grid-template-columns: var(--chart-width) 1fr;

        .chart-legend {
            position: relative;

            &::before {
                position: absolute;
                content: '';
                height: 100%;
                width: var(--chart-width);
                left: 0;
                transform: translateX(-100%);
                background-color: inherit;
                clip-path: polygon(50% 50%, 100% 100%, 100% 0);
                z-index: -1;
            }
        }
    }

    &.chart-right {
        grid-template-columns: 1fr var(--chart-width);

        .chart-legend {
            grid-row: 2;
            grid-column: 1;

            position: relative;

            &::after {
                position: absolute;
                content: '';
                height: 100%;
                width: var(--chart-width);
                right: 0;
                transform: translateX(100%);
                background-color: inherit;
                clip-path: polygon(50% 50%, 0 100%, 0 0);
                z-index: -1;
            }
        }

        .chart-container {
            grid-row: 2;
            grid-column: 2;
        }
    }
}

.chart-container[data-type="bar"] {
    background-color: $white;
    padding: calc(var(--main-padding) / 2);
}

.realestate-stats {
    margin-top: var(--main-padding);

    h2 {
        margin-bottom: calc(var(--main-padding) / 2);
        font-size: 1.5rem;
    }

    .form-title {
        margin-bottom: 1rem;
    }

    div + .form-title {
        margin-top: 2rem;
    }

    .realestate-units-situation {
        background: $white;
        padding: calc(var(--main-padding) / 2);
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        position: relative;

        svg {
            height: auto;
            max-height: 60vh;

            text, circle {
                pointer-events: none;
            }

            .realestate-clickable-unit {
                cursor: pointer;
                transition: filter .25s;

                &:hover {
                    filter: brightness(80%);
                }
            }
        }
    }

    .realestate-units-situation-popup {
        position: absolute;
        background-color: $white;
        filter: drop-shadow(0 0 5px rgba(0,0,0, .3));
        padding: calc(var(--main-padding) / 3);
        transform: translate(-50%, calc(-100% - 1rem));

        opacity: 0;

        &.visible {
            opacity: 1;
        }

        .realestate-units-situation-popup-close {
            cursor: pointer;
            position: absolute;
            top: .25rem;
            right: .25rem;

            svg {
                fill: currentColor;
                width: 1rem;
                height: 1rem;
            }
        }

        .realestate-units-situation-popup-content {
            padding-top: .5rem;
        }

        p {
            white-space: nowrap;
        }

        th {
            font-weight: 700;
            white-space: nowrap;
        }

        th, td {
            text-align: left;
            &:not(:last-of-type) {
                padding-right: calc(var(--main-padding) / 2);
            }
        }

        &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 12.5px 12.5px 0 12.5px;
            border-color: $white transparent transparent transparent;
            position: absolute;
            top:100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .stat-row[data-stat="realestate-table"] {
        background-color: $white;
        padding: calc(var(--main-padding) / 2) calc(var(--main-padding) / 3);
        box-sizing: border-box;

        table {
            width: 100%;
        }

        tr.filtering-by-row {
            color: var(--main-color);
        }
    }
}

