.lang-switcher {
    list-style: none;
    display: flex;
    li {
        display: flex;
    }
    .small-button {
        background-color: var(--main-color);
        &.active {
            background-color: var(--secondary-color);
        }
    }
}