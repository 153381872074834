//Add top margin for the first parent
.cp-version-table {
    + .changed-pages-parent {
        margin-top: 2rem;
    }
}

.changed-pages-parent {
    h2 {
        margin-bottom: 1rem;
    }

    + .changed-pages-parent {
        margin-top: 2rem;
    }
}

.changed-page-container {
    + .changed-page-container {
        margin-top: 1rem;
    }

    &.opened {
        .changed-page-title svg {
            transform: rotate(-180deg);
        }
    }

    svg {
        transition: transform .25s;
    }
}

.changed-page-header {
    display: grid;
    grid-template-columns: 1fr auto;
}

.changed-page-rollback {
    height: 100%;
    width: auto;
    aspect-ratio: 1/1;
    background-color: darken($grey, 15%);
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1.25rem;
    box-sizing: border-box;

    transition: color .25s;

    cursor: pointer;

    svg {
        width: 100%;
        height: 100%;
    }

    &:hover {
        background-color: darken($grey,25%);
    }
}

.changed-page-title {
    background-color: $grey;
    padding: 1rem 2rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
}

.changed-page-content {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    background-color: darken($grey, 15%);


    padding: var(--main-padding);
    box-sizing: border-box;

    ul {
        margin-left: 1em;
    }

    del {
        background-color: rgba(255, 0, 0, .25);
        text-decoration: line-through;
    }

    ins {
        background-color: rgba(0, 255, 0, .25);
    }
}