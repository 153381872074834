button {
    svg, img {
        pointer-events: none;
    }
}

.button {
    background-color: var(--main-color);
    padding: 0.75em 1.5em;
    color: $white;

    font-family: $serenity;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;

    transition: background-color .25s;

    &[type="submit"] {
        &.loading {
            pointer-events: none;
            &:after {
                background-image: url('../content/img/icon-loading.svg');
                animation: loading 1s linear infinite;
            }
        }

        &[disabled] {
            background-color: $grey;
            pointer-events: none;
        }
    }

    &:after {
        content: '';

        display: flex;

        aspect-ratio: 1;
        width: 0.75em;
        height: auto;

        background-image: url('../content/img/icon-next.svg');
        background-position: 100%;
        background-repeat: no-repeat;

        margin-left: 0.75em;
    }

    &:hover {
        background-color: darken($orange, 10%);
    }
}

.back-button {
    align-items: center;
    display: flex;
    gap: 0.75em;
    
    color: var(--main-color);

    &:hover {
        text-decoration: underline;
    }

    svg {
        display: inline-block;
        fill: currentColor;
        width: 0.5em;
        height: auto;
    }

}

.small-button {
    transition: background-color .25s;
    color: $white;
    font-family: $serenity;
    font-weight: 700;
    font-size: 1.2rem;
    padding: .375em 0.75em;
    background-color: var(--secondary-color);
    &.orange {
        background-color: var(--main-color);

        &:hover {
            background-color: darken($orange, 10%);
        }
    }

    &:hover {
        background-color: darken($blue, 10%);
    }
}

.inline-button {
    font-style: italic;
    text-decoration: none;
    color: var(--secondary-color);
    font-family: $serenity;
    font-weight: 600;

    &.white {
        color: $white;
    }

    &.clear-field {
        transition: opacity .25s;
        pointer-events: none;
        color: var(--main-color);
        opacity: 0;
    }

    &:hover {
        text-decoration: underline;
    }

}

.alt-button {
    align-self: stretch;
    align-items: center;
    display: flex;
    gap: .25em;

    margin-left: 0.25rem;

    color: rgba($darkgrey, 0.5);
    white-space: nowrap;
    line-height: 1em;
    font-size: .9rem;

    transition: color .25s;

    &:hover {
        color: var(--main-color);
    }

    svg {
        line-height: 0;
        display: block;
        height: auto;
        width: 1em;
    }
}

@media (max-width: 1200px) {
    .inline-button {
        font-size: 0.9em;
    }
    .button {
        font-size: 1.2rem;
    }
    .small-button {
        font-size: 1rem;
    }
}