body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    overflow-x: hidden;

    background-color: $lightgrey;
}

main {
    min-height: calc(100vh - var(--topbar-height));
    width: calc(100% - var(--nav-width));
    margin-top: var(--topbar-height);
    margin-left: var(--nav-width);
    padding: var(--main-padding);
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.main-content {
    flex-direction: column;
    display: flex;
    &.full-width {
        grid-column: span 2;
    }
    > .main-fields {
        &:where(:not(.user-permissions)) {
            flex-direction: column;
            display: flex;
            flex-grow: 1;
            .field, .cke, .cke_inner {
                flex-direction: column;
                display: flex;
            }
            .field, .cke, .cke_inner, .cke_contents {
                flex-grow: 1;
            }
        }
    }
}

.content-grid {
    display: grid;
    grid-template-columns: calc(100% - var(--sidebar-width) - var(--main-padding)) var(--sidebar-width);
    gap: calc(var(--main-padding) / 2) var(--main-padding);
    .grid-title {
        grid-column: span 2;
        user-select: none;
        h2 {
            color: var(--main-color);
        }
    }
}

.cms-form {
    .content-grid {
        display: none;

        &.visible {
            display: grid;
        }
    }
}

[hidden] {
    display: none;
}

@media (max-width: 1000px) {
    body {
        &.nav-open {
            .topbar, main {
                transform: translateX(var(--nav-width));
            }
        }
    }
    main {
        transition: transform .25s;
        margin-left: 0;
        width: 100%;
    }    
}