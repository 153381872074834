.cms-sidebar {
    top: calc(var(--topbar-height) + var(--main-padding));
    right: 0;
    width: 100%;

    position: sticky;
    align-self: start;

    display: flex;
    flex-direction: column;
    gap: 2rem 1rem;

    user-select: none;

    .button {
        margin-right: auto;

        &.selected-action {
            display: none;
        }
    }

    .message {
        margin-right: 2rem;
    }

    .sidebar-text {
        background-color: $white;
        color: $black;
        padding: calc(var(--main-padding) / 1.5);
        user-select: text;
    }

    .sidebar-block {
        display: flex;
        flex-direction: column;
        padding: calc(var(--main-padding) / 1.5);
        color: $white;
        &.blue {
            background-color: var(--secondary-color);
        }

        &.darkgrey {
            background-color: $darkgrey;

            a {
                color: var(--main-color);
            }
        }
    }

    .block-title {
        display: flex;
        align-items: center;
        font-family: $serenity;
        font-size: 1.4rem;

        svg {
            display: flex;
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 1rem;
        }
    }

    .block-list {
        list-style-type: none;
        margin-top: 1rem;
    }
}

.cms-page-image-fields {
    .field {
        button.open-lightbox{
            margin-left: auto;
        }
    }
}