html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    outline: none;
    vertical-align: baseline;
    text-decoration: none;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    line-height: 1;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
input[type=text]::-ms-clear { display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal { display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: inset 0 0 0px 9999px $lightgrey;
    -webkit-text-fill-color: inherit;
}
select::-ms-expand {
    display: none;
}
textarea {
    overflow: auto;
}

input[type="number"] {
    -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
    appearance: none;
    margin: 0; 
}
input, input:focus {
    outline: none;
}
input, textarea, select {
    box-sizing: border-box;
    display: inline-block;
    line-height: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: inherit;
    border-radius: 0;
    appearance: none;
    background: none;
    color: inherit;
    outline: none;
    border: none;
    width: auto;
    padding: 0;
    margin: 0;
}
button, .button {
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    line-height: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: inherit;
    user-select: none;
    border-radius: 0;
    appearance: none;
    background: none;
    cursor: pointer;
    color: inherit;
    outline: none;
    border: none;
    width: auto;
    padding: 0;
    margin: 0;
}