.cms-nav-container {
    position: fixed;
    z-index: 2;

    display: flex;
    flex-direction: column;

    height: 100%;
    width: var(--nav-width);
    background-color: var(--secondary-color);

    box-sizing: border-box;

    user-select: none;
    overflow-y: scroll;

    a.logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3rem 2rem;

        img {
            //Calculate 40% of total width (prevents SVG issues with AspectRatio in Firefox & Safari)
            width: calc(var(--nav-width) / 2);
            object-fit: contain;
        }
    }

    .nav-block {
        &:not(:last-of-type) {
            margin-bottom: 0.5rem;
        }

        a {
            transition: color .25s;

            &:hover,
            &.active {
                color: var(--main-color);
            }
        }
    }

    .nav-label {
        display: flex;

        padding: 0.5rem 2rem;

        color: $white;
        font-weight: 700;
        font-family: $serenity;
        font-size: 1.3em;

        &.active {
            border-left: 4px solid var(--main-color);
            padding-left: calc(2rem - 4px);
            color: var(--main-color);
        }

        svg {
            width: 1.4rem;
            height: 1.4rem;
            margin-right: 1rem;
        }
    }

    .nav-submenu {
        list-style-type: none;
        margin-left: calc(1.4rem + 2rem + 1rem);
        font-size: .9em;

        li {
            display: flex;
        }

        a {
            color: $white;
            width: 100%;
            padding: .4rem .4rem .4rem 0;
            line-height: 1.2em;
            position: relative;

            &:after {
                width: var(--nav-width);
                height: 100%;

                position: absolute;
                bottom: 0;
                right: 0;
                top: 0;

                content: '';
            }
        }
    }

    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .cms-version-indicator {
        padding: 0.25rem calc(2rem - 4px);
        padding-bottom:1rem;
        box-sizing: border-box;
        margin-top: auto;
        color: $white;
        opacity: .25;
        width: 100%;
        line-height: 1.8em;
    }
}

@media (max-width: 1000px) {
    .cms-nav-container {
        transition: transform .25s;
        font-size: 1.2rem;
        &:where(:not(.open)) {
            transform: translateX(-100%);
        }
    }
}