.cp-feedback-table {
    .feedback-processed {
        display: none;
    }

    &.show-processed-feedback {
        .feedback-processed {
            display: table-row;
        }
    }
}

.cp-preview-page {
    color: var(--main-color);
    transition: color .25s;

    &:hover {
        color: var(--main-color-alt);
    }
}