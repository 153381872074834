main.login {
    min-height: 100vh;
    width: 100%;

    margin: 0;
    padding: 0;

    font-family: 'Nunito', sans-serif;

    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    flex-direction: column;

    aside {
        display: none;
        flex-direction: column;

        background-color: var(--secondary-color);
        height: 100%;

        color: $white;

        box-sizing: border-box;

        padding: var(--main-padding);

        h1 {
            color: var(--main-color);
        }
    }

    .cms-version {
        font-size: .70rem;
        line-height: 2em;
        opacity: .5;
    }

    .cms-intro {
        padding: 2rem 0;
        font-size: 1.2rem;
    }

    .cms-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;
        padding: var(--main-padding);
        box-sizing: border-box;

    }

    .base-login-form {
        background-color: $white;
        padding: 4.25rem;
        display: flex;
        flex-direction: column;
        max-width: 360px;
        width: 100%;
        box-sizing: border-box;

        .cp-login-logo {
            width: 100%;
            max-width: 14.5rem;
            margin: 0 auto calc(var(--main-padding) / 1.6);

            +fieldset {
                margin-top: 2rem;
            }
        }

        label {
            font-family: inherit;
            font-size: 1.4rem;
            color: var(--secondary-color);

            &:not(:first-of-type) {
                margin-top: 1rem;
            }
        }

        input {
            background-color: transparent;
            border-bottom: 1px solid black;
            //padding: .5rem 1rem;
            padding: 0;
            width: 100%;

            font-size: .97rem;

            color: black;

            height: 45px;
            &::placeholder {
                color: rgba(0,0,0, .8);
            }

            +input {
                margin-top: .25rem;
            }
        }
    }

    .cp-title-block {
        display: flex;
        flex-direction: column;
        gap: .75rem;
    }

    h1, h2, h3 {
        font-family: inherit;
        font-size:1.275rem;
        font-weight: 300;
        text-align: center;
        margin: 0;
        color: var(--base-color, var(--main-color));
    }

    h3 {
        font-size: 1.2rem;
        font-weight: 700;
    }

    fieldset {
        display: flex;
        flex-direction: column;
        margin: calc(var(--main-padding) / 1.75) 0;

        min-width: 220px;
    }

    .button {
        width: 100%;
        background-color: var(--base-color, var(--main-color));
        text-align: center;
        align-items: center;
        justify-content: center;

        transition: filter .25s, background-color .25s;

        padding: 1.5rem;

        font-weight: 400;
        font-size: 1.55rem;

        &:after {
            display: none;
        }

        &:hover {
            filter: brightness(80%);
        }
    }

    .forgot-pass-btn {
        margin-top: 1rem;
        text-align: center;
        color: black;
        font-size: .95rem;
    }

    .message {
        margin-top: 2rem;
        text-align: center;
        font-size: .9rem;
        &.error {
            color: red;
        }
    }
}

.cms-login.cp-using-plan-styling {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    z-index: 0;

    &:before {
        content: '';
        display: flex;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: black;
        opacity: .3;
        z-index: -1;
    }
}

input:focus + .password-requirements-popup {
    opacity: 1;
}

.create-strong-password-container {
    position: relative;

    .password-requirements-popup {
        transition: opacity .25s;
        pointer-events: none;
        opacity: 0;
        position: absolute;
        top: 50%;
        right:  0;
        transform: translate(calc(100% + 1.5rem), -50%);
        background-color: $white;
        filter: drop-shadow(0 0 25px rgba(0,0,0, .2));
        padding: calc(var(--main-padding) / 3);

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            content: '';
            transform: translate(-100%, -50%);

            width: 0;
            height: 0;
            border-style: solid;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-right: 12px solid $white;
            border-left: 0;
        }
    }

    ul {
        list-style-type: none;
    }

    li {
        color: red;
        display: grid;
        grid-template-columns: 1.5rem 1fr;
        align-items: center;
        gap: .25rem;
        transition: color .25s;

        .requirement-matched-icon {
           opacity: 0;
        }
        .requirement-failed-icon {
            opacity: 1;
        }

        .requirement-matched-icon, .requirement-failed-icon {
            grid-column: 1;
            grid-row: 1;
            transition: opacity .25s;
        }

        &.requirement-completed {
            color: green;

            .requirement-matched-icon {
                opacity: 1;
            }
            .requirement-failed-icon {
                opacity: 0;
            }
        }
    }
}


@media (max-width: 1000px) {
    main.login {
        grid-template-rows: 1fr auto;
        grid-template-columns: 1fr;
        .cms-login {
            height: auto;
            grid-row: 1;
        }
        aside {
            height: auto;
            grid-row: 2;
        }
    }
}