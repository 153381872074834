html {
    font: 400 14px/1.75em $source;
}

body {
    font: inherit;
    color: $darkgrey;
}

h1 {
    font-family: $serenity;
    line-height: 1em;
    font-size: 3rem;
    font-weight: 700;
    color: $darkgrey;

    &.orange {
        color: var(--main-color);
    }
}

h2 {
    font-family: $serenity;
    font-weight: 600;
    line-height: 1em;
    font-size: 2rem;
}

h3,
b,
strong {
    font-family: $serenity;
    color: var(--main-color);
    font-weight: 700;
    font-size: 1.2rem;
}

em {
    font-style: italic;
}

a:not[class] {
    color: $black;
    transition: color .25s;

    &:hover {
        color: var(--main-color);
    }
}

mark {
    background-color: $lightgrey;
    font-family: monospace;
    padding: 0 0.25em;
}

small {
    font-size: .75rem;
}

s {
    text-decoration: line-through;
}

.page-title {
    position: absolute;
    left: var(--main-padding);
    bottom: 0;
    transform: translateY(50%);
}

@media (max-width: 1800px) {
    html {
        font-size: 13px;
    }
}
@media (max-width: 1400px) {
    html {
        font-size: 12px;
    }
}
@media (max-width: 1000px) {
    .page-title {
        margin-right: var(--main-padding);
        align-self: center;
        position: static;
        transform: none;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        max-width: 100%;

        font-size: 2rem;
    }
}