@keyframes loading {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes line1-open {
    0% {
        transform: translate(-50%, inherit);
    }
    50% {
        transform: translate(-50%, 25%);
    }
    100% {
        transform: translate(-50%, 0) rotate(45deg);
    }
}
@keyframes line2-open {
    0% {
        transform: translate(-50%, inherit);
    }
    50% {
        transform: translate(-50%, -25%);
    }
    100% {
        transform: translate(-50%, 0) rotate(-45deg);
    }
}
@keyframes line1-closed {
    0% {
        transform: translate(-50%, 25%) rotate(45deg);
    }
    50% {
        transform: translate(-50%, 25%) rotate(0);
    }
    100% {
        transform: translate(-50%, inherit) rotate(0);
    }
}
@keyframes line2-closed {
    0% {
        transform: translate(-50%, -25%) rotate(-45deg);
    }
    50% {
        transform: translate(-50%, -25%) rotate(0);
    }
    100% {
        transform: translate(-50%, inherit) rotate(0);
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 10px;

    transform: scale(.75);
    margin-top: 1rem;
    
    transform-origin: left;
}
.lds-ellipsis div {
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
