.table-container {
    background-color: $white;
    padding: calc(var(--main-padding) / 1.5) calc(var(--main-padding) / 3);
    box-sizing: border-box;
    overflow-x: auto;
    width: 100%;
}

.cms-table {
    width: auto;
    min-width: 100%;
    border-collapse: collapse;

    &.filtering {
        tbody tr {
            display: none;

            &.matches-filter {
                display: table-row;
            }
        }
    }

    &.sortable,
    &[data-dbtable="cms_users"] {
        user-select: none;
    }

    &.sortable {
        &:not(.dragging) {
            &.white {
                tbody {
                    tr {
                        &:hover {
                            background-color: $grey;
                        }
                    }
                }
            }

            tbody {
                tr {
                    cursor: move;

                    &:hover {
                        background-color: $lightgrey;
                    }
                }
            }
        }

        &.white {
            .sortable-ghost {
                background-color: $grey;
            }
        }

        tbody {
            tr {
                transition: background-color .25s;
            }
        }
    }

    .select-row {
        appearance: checkbox;
    }

    th {
        text-align: left;
        color: var(--main-color);
        font-family: $serenity;
        line-height: 1em;
        font-size: 1.2rem;
    }

    th {
        padding: 0 .75rem .75rem;

        &:first-of-type {
            padding-left: calc(var(--main-padding) / 3);
        }

        &:last-of-type {
            padding-right: calc(var(--main-padding) / 3);
        }
    }

    td {
        &:not(.icon) {
            padding: .75rem;
        }

        &.nowrap {
            white-space: nowrap;
        }

        &:first-of-type {
            padding-left: calc(var(--main-padding) / 3);
        }

        &:last-of-type {
            padding-right: calc(var(--main-padding) / 3);
        }

        border-bottom: 1px solid rgba($blue, .2);
    }

    td.checkbox {
        width: 1rem;
    }

    td.icon {
        width: 10px;
        color: var(--secondary-color);

        text-align: right;

        a {
            color: inherit;
        }

        a,
        button {
            transition: color .25s;
            padding: .75rem;

            &:hover {
                color: $orange
            }
        }

        svg {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    &.white {
        color: $white;

        td.icon {
            color: $white;
        }
    }

    .switch-visibility,
    .switch-layer-visibility,
    .switch-processed {
        .check {
            display: none;
        }

        .disabled {
            display: flex;
        }

        &.visible {
            .check {
                display: flex;
            }

            .disabled {
                display: none;
            }
        }
    }

    &.expandable-table {
        tbody {
            td:first-of-type {
                white-space: nowrap;
            }

            td.expandable-column {
                width: 100%;
            }
        }

        ul {
            display: flex;
            flex-wrap: wrap;

            max-height: 1.4em;
            overflow-y: hidden;

            list-style-type: none;
            line-height: 1.75em;
            font-size: .8rem;
        }

        li {
            margin-right: .75rem;

            strong {
                font-size: inherit;
                font-size: 1rem;
            }
        }

        .expandable-column.expanded {
            ul {
                flex-direction: column;
                font-size: 1rem;

                max-height: none;
                overflow-y: auto;
            }

            li {
                display: block;

                strong {
                    font-size: 1.22rem;
                }
            }
        }

        button.expand-list {
            color: var(--main-color);
            font-size: .8rem;
        }
    }
}

.sortable-ghost {
    background-color: $lightgrey;
    cursor: move;
}