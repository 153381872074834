.topbar {

    position: fixed;
    display: flex;
    left: var(--nav-width);

    background-color: $white;

    height: var(--topbar-height);
    width: calc(100% - var(--nav-width));

    padding-right: 2rem;

    box-sizing: border-box;
    user-select: none;

    z-index: 10;

    .back-button {
        width: var(--main-padding);
        svg {
            width: 0.7rem;
        }
        span {
            line-height: 0;
            font-size: 0;
        }
    }

    ul {
        display: flex;
        margin-left: auto;
        list-style-type: none;
        gap: 2rem;

        li {
            display: flex;
        }
    }

    a,
    button:where(:not(.menu-toggle)) {
        justify-content: center;
        align-items: center;
        display: flex;
        color: $grey;

        gap: 1rem;

        transition: color .25s;

        &:hover,
        &.active {
            color: var(--main-color);
        }
        &.back-button {
            gap: 0;
        }
    }

    svg {
        overflow: visible;
        width: 1.4rem;
        height: auto;
        * {
            transition: fill .25s;
        }
    }

    .theme-toggle {
        grid-template-areas: 'text icon';
        display: grid;
        &[data-cms-theme-dark="false"] {
            svg.theme-toggle-icon-light {
                display: block;
            }
        }
        &[data-cms-theme-dark="true"] {
            svg.theme-toggle-icon-dark {
                display: block;
            }
        }
        svg {
            grid-area: icon;
            display: none;
        }
    }

    .menu-toggle {
        padding: 0 var(--main-padding);
        justify-content: center;
        box-sizing: content-box;
        align-items: center;
        position: relative;
        display: none;
        font-size: 0;
        width: 2rem;
        z-index: 4;
        &:focus-visible, &:active {
            outline: none;
        }
        &.open {
            &::before {
                animation: line1-open 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25) 1 forwards;
            }
            span {
                opacity: 0;
            }
            &::after {
                animation: line2-open 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25) 1 forwards;
            }
        }
        &.closed {
            &::before {
                animation: line1-closed 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25) 1 forwards;
            }
            &::after {
                animation: line2-closed 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25) 1 forwards;
            }
        }
        &::before, &::after, span {
            background: currentColor;
            pointer-events: none;
            position: absolute;
            width: 2rem;
            height: 2px;
            content: '';
            right: 0;
            left: 50%;
        }
        &::before {
            transform: translate(-50%, -0.5rem);
            transform-origin: 50%;
        }
        &::after {
            transform: translate(-50%, 0.5rem);
            transform-origin: 50%;
        }
        span {
            transform: translate(-50%, 0);
            transition: opacity .25s;
        }
    }

}

@media (max-width: 1000px) {
    .topbar {
        grid-template-areas: 'menutoggle pagetitle buttons';
        grid-template-columns: max-content max-content 1fr;
        display: grid;
        transition: transform .25s;
        width: 100%;
        left: 0;
        .menu-toggle {
            grid-area: menutoggle;
            display: flex;
        }
        .back-button, .page-title {
            grid-area: pagetitle;
        }
        .back-button {
            justify-content: flex-start;
            cursor: pointer;
            width: 100%;
            z-index: 2;
            + .page-title {
                padding-left: 1.7rem;
                cursor: pointer;
            }
        }
        .page-title {
            z-index: 1;
        }
    }
    ul {
        grid-area: buttons;
    }
}