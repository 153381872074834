//Fonts
$serenity: serenity, sans-serif;
$source:  open-sans, sans-serif;

//Colors
$black: #000;
$white: #FFF;

$lightgrey: #ECEBF0;
$grey: #636362;
$darkgrey: #2E2E2D;

//Base CMS Color
$blue: #405B85;
$orange: #ff5600;

$orangeAlt: lighten($orange, 10%);
$blueAlt: lighten($blue, 10%);

$orangeDark: darken($orange, 15%);
$blueDark: darken($blue, 15%);

:root {
    --nav-width: 20rem;
    --topbar-height: 5rem;
    --sidebar-width: 30%;

    --main-padding: 4rem;

    --main-color: #{$orange};
    --secondary-color: #{$blue};

    --main-color-alt: #{$orangeAlt};
    --secondary-color-alt: #{$blueAlt};

    --main-color-dark: #{$orangeDark};
    --secondary-color-dark: #{$blueDark};
}

@media (max-width: 1800px) {
    :root {
        --nav-width: 18rem;
        --main-padding: 3rem;
    }
}
@media (max-width: 1400px) {
    :root {
        --main-padding: 2.5rem;
    }
}
@media (max-width: 1000px) {
    :root {
        --nav-width: 20rem;
    }
}