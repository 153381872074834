.menu-structure {
    margin-top: 0.5rem;

    &:where(:not(.dragging)) {
        .item {
            &:hover {
                background-color: $grey;
            }
        }
    }

    .sub-items {
        margin-left: 2rem;
    }

    .menu-item {
        &.sortable-chosen {
            > .item {
                background-color: $grey;
            }
        }
    }

    .edit-form {
        transition: max-height .25s;
        background-color: $white;
        flex-direction: column;
        box-sizing: border-box;
        pointer-events: none;
        grid-column: span 4;
        overflow: hidden;
        max-height: 0;
        display: flex;
        width: 100%;
        opacity: 0;
        &.visible {
            pointer-events: auto;
            opacity: 1;
        }

        fieldset {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 1rem;
        }

        input, select {
            color: $black
        }

        .field {
            margin-bottom: 0;
        }

        .small-button {
            display: flex;
            margin-left: auto;
            margin-top: .5rem;
        }

        .menu-icon-edit {
            .inline-button:last-of-type {
                margin-left: auto;
            }
        }
    }

    .item {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr)) auto auto;
        transition: background-color .25s;
        background-color: var(--secondary-color);
        color: $white;
        margin-bottom: .5rem;
        gap: 0 1rem;
        cursor: move;
    }

    .name, .type {
        padding: .5rem 1rem;
        box-sizing: border-box;
    }

    .edit-menu, .delete-menu {
        display: flex;
        align-items: center;
        img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    .delete-menu {
        padding-right: 1rem;
    }
}

.add-menu, .page-filters {
    fieldset {
        &:first-of-type {
            label {
                font-family: $serenity;
                font-size: 1.2rem;
                font-weight: 700;
                color: var(--main-color);
            }
        }
        &.sidebar-fields {
            background-color: var(--secondary-color);
            label {
                color: $white;
                &:nth-of-type(2), &:nth-of-type(3) {
                    font-size: 1.25em;
                    font-weight: 400;
                }
            }
            .prefix {
                span {
                    color: $white;
                }
            }
        }
    }
    input {
        &[type="radio"] {
            + label {
                font-weight: 400;
                &:before {
                    border: none;
                }
            }
            &:checked {
                +label {
                    &:before {
                        background: var(--main-color);
                    }
                }
            }
        }
    }
}